import React, { useState } from "react";
import { Table, Button, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { USERS } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FileAddOutlined } from "@ant-design/icons";

const List = () => {
  const me = useSelector(state => state.me);
  const permissions = useSelector(state => state.permissions);
  const [state, setState] = useState({
    page: 1
  });
  const { page } = state;
  let pageTotal = 1;
  const { data, loading, error } = useQuery(USERS, {
    variables: { page, count: 10, branch_id: me.branch.id },
    fetchPolicy: "cache-and-network"
  });
  if (loading) {
    return <Spin spinning={loading} />;
  }
  pageTotal = data.users.paginationInfo.total;
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <React.Fragment>
          <span>{record.name}</span>
        </React.Fragment>
      )
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text, record) => (
        <React.Fragment>
          <span>{record.phone_number}</span>
          <br />
          <span>{record.email}</span>
        </React.Fragment>
      )
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <React.Fragment>
          {permissions.CAN_UPDATE_USER && (
            <Link to={`/users/${record.id}/edit`}>
              <Button size="small" type="primary">
                Edit
              </Button>
            </Link>
          )}
        </React.Fragment>
      )
    }
  ];
  return (
    <React.Fragment>
      <PageHeader
        title="Users"
        extra={
          permissions.CAN_UPDATE_USER && [
            <Link to={`/users/add`}>
              <Button icon={<FileAddOutlined />} type="primary" key="adduser">
                Add
              </Button>
            </Link>
          ]
        }
      />

      <Table
        dataSource={data.users.data}
        columns={columns}
        pagination={{
          total: pageTotal,
          onChange: (page, pageSize) => setState({ ...state, page })
        }}
      />
    </React.Fragment>
  );
};

export default List;
