import React, { useState } from "react";
import { Table, Button, Form, Input } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { Link } from "react-router-dom";
import { TRANSACTIONS_BUY } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { FileAddOutlined } from "@ant-design/icons";
const List = () => {
  const me = useSelector(state => state.me);
  const permissions = useSelector(state => state.permissions);
  const [state, setState] = useState({
    pageNumber: 1,
    keyword: null
  });
  const { pageNumber, keyword, province, district } = state;
  const { data, loading, error } = useQuery(TRANSACTIONS_BUY, {
    fetchPolicy: "cache-and-network",
    variables: {
      keyword: keyword,
      page: pageNumber,
      count: 10
    }
  });
  let pageTotal = 0;
  if (!loading) {
    pageTotal = data.transactionListBuy.paginationInfo.total;
  }
  const columns = [
    {
      title: "INV",
      dataIndex: "invoice_number",
      key: "invoice_number",
      render: (text, record) => (
        <span>
          <Link to={`/buy/${record.id}/view`}>{record.invoice_number}</Link>
          <br />
          {record.seller.name}
          <br />
          {record.created_at}
        </span>
      )
    },
    {
      title: "Grandtotal",
      dataIndex: "grandtotal",
      key: "grandtotal",
      align: "right",
      render: (text, record) => (
        <span>{window.idCurrency.formatSimple(record.grandtotal)}</span>
      )
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Link to={`/buy/${record.id}/view`}>View</Link>
        </span>
      )
    }
  ];

  const onLocationSelect = value => {
    setState({
      ...state,
      province: value[0],
      district: value[1]
    });
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Buy List"
        extra={
          permissions.CAN_CREATE_TRANSACTION_BUY &&
          me.branch.id === 1 && [
            <Link to="/buy/add" key="add">
              <Button type="primary" icon={<FileAddOutlined />}>
                {" "}
                Add
              </Button>
            </Link>
          ]
        }
      />
      <Form layout="inline">
        <Form.Item label="Search">
          <Input
            type="text"
            allowClear
            placeholder="eg. Tony"
            onPressEnter={e => {
              setState({
                ...state,
                keyword: e.target.value,
                pageNumber: 1
              });
            }}
          />
        </Form.Item>
      </Form>
      <Table
        pagination={{
          total: pageTotal,
          onChange: (page, pageSize) => setState({ ...state, pageNumber: page })
        }}
        columns={columns}
        rowKey={record => record.id}
        loading={loading}
        dataSource={loading ? [] : data.transactionListBuy.data}
      />
    </React.Fragment>
  );
};

export default List;
