import React, { useState } from "react";
import {
  Descriptions,
  DatePicker,
  Form,
  Modal,
  Button,
  Switch,
  Tag,
  Spin,
  Typography,
  Input,
} from "antd";
import BranchSelect from "UI/Input/BranchSelect";
import TvSelect from "UI/Input/TvSelect";
import StatusSelect from "UI/Input/StatusSelect";
import ProductMultiSelect from "UI/Input/ProductMultiSelect";
import qs from "qs";
import { useLazyQuery } from "@apollo/client";
import { REPORT_BY_PRODUCT } from "GraphQL/Queries";
import ProductDayTable from "./ProductDayTable";
import { useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const ProductsModal = props => {
  const { onOk, visible, selected: selectedProducts, onCancel } = props;
  let selected = selectedProducts.map(product => product.id);
  const handleChange = selectedProducts => {
    selected = selectedProducts;
  };
  const [all, setAll] = useState(selected.length === 0);
  const handleOk = () => {
    if (all) {
      onOk([]);
    } else {
      onOk(selected);
    }
  };
  const handleAllProduct = disabled => {
    setAll(disabled);
  };

  return (
    <Modal
      onCancel={onCancel}
      open={visible}
      onOk={handleOk}
      title="Select products"
    >
      <ProductMultiSelect
        disabled={all}
        selected={selected}
        onChange={handleChange}
      />
      <Switch
        unCheckedChildren="All"
        checkedChildren="All"
        checked={all}
        onChange={handleAllProduct}
        style={{ marginTop: 16 }}
      />
    </Modal>
  );
};

const FilterForm = props => {
  const me = useSelector(state => state.me);
  const [ form ] = Form.useForm();
  let tv_id = 0;
  me.meta.forEach(meta => {
    if(meta.key === "tv_id"){
      tv_id = meta.value;
    }
  })
  const [state, setState] = useState({
    productsModalVisible: false,
    selectedProducts: []
  });
  const { productsModalVisible, selectedProducts } = state;
  const { submit } = props;
  const handleSubmit = values => {
      values.branch_id = "all";
      values.tv_id = tv_id;
      values.products = selectedProducts.map(product => product.id);
      submit(values);
  };

  const handleDownloadButton = () => {
    form.setFieldValue('download', true);
    form.submit();
  }
  return (
    <React.Fragment>
      {productsModalVisible && (
        <ProductsModal
          selected={selectedProducts}
          visible={productsModalVisible}
          onOk={products =>
            setState({
              ...state,
              selectedProducts: products,
              productsModalVisible: false
            })
          }
          onCancel={() => {
            setState({ ...state, productsModalVisible: false });
          }}
        />
      )}
      <Form onFinish={handleSubmit} form={form}>
        <Descriptions bordered title="Filter">
          <Descriptions.Item label="Date" span={3}>
            <Form.Item name="dateRange" rules={[{ required: true, message: "Date range required" }]}>
              <RangePicker />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Inv Status">
            <Form.Item name="status" initialValue="ALL">
                <StatusSelect />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Products" span={2}>
            <Form.Item>
              {selectedProducts.map(product => (
                <Tag key={product.id}>
                  ({product.id}) {product.name}
                </Tag>
              ))}{" "}
              {selectedProducts.length === 0 ? "All products " : ""}
              <Button
                onClick={() => {
                  setState({ ...state, productsModalVisible: true });
                }}
              >
                Select ...
              </Button>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item>
            <Button htmlType="submit" type="primary">
              Generate
            </Button>
            <Button
              type="link"
              htmlType="submit"
              icon={<DownloadOutlined />}
              onClick={handleDownloadButton}
            >
              Download
            </Button>
          </Descriptions.Item>
        </Descriptions>
        <Form.Item name="download" hidden>
          <Input />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

const ByProduct = () => {
  const [getReport, { data, error, loading, refetch }] = useLazyQuery(
    REPORT_BY_PRODUCT,
    { fetchPolicy: "network-only" }
  );

  const handleSubmit = params => {
    const { dateRange, download, products, ...paramsRest } = params;
      paramsRest.branch_id = null;
    if (paramsRest.tv_id === "all") {
      paramsRest.tv_id = null;
    }
    else {
      paramsRest.tv_id = Number(paramsRest.tv_id);
    }
    if (paramsRest.status === "ALL") {
      paramsRest.status = null;
    }
    paramsRest.date_from = dateRange[0].format("YYYY-MM-DD");
    paramsRest.date_to = dateRange[1].format("YYYY-MM-DD");
    if (download) {
      paramsRest.products_id = products.join(",");
      window.location.href = `${process.env.REACT_APP_SERVER_API_URL}/server/reports_tv?` + qs.stringify(paramsRest);
    } else {
      paramsRest.products_id = products;
      console.log(paramsRest)
      getReport({ variables: paramsRest });
    }
  };
  if (!loading) {
    console.log('zzz', data);
  }

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <FilterForm submit={handleSubmit} />
        {!loading &&
          data &&
          data.productTransaction.data.map(dt => (
            <React.Fragment key={dt.created_at}>
              <br />
              <Descriptions bordered layout="vertical">
                <Descriptions.Item label={dt.created_at}>
                  {console.log(dt.created_at, dt.products)}
                  {dt.products.map(product => (
                    <React.Fragment key={product.id}>
                      <Text strong>Product name: {product.name}</Text>
                      <ProductDayTable
                        product={product}
                        data={product.inventories}
                      />
                      <br />
                    </React.Fragment>
                  ))}
                </Descriptions.Item>
              </Descriptions>
              <br />
            </React.Fragment>
          ))}
      </Spin>
    </React.Fragment>
  );
};
export default ByProduct;
