import { createStore, bindActionCreators } from "redux";

const initialState = {
  locations: {},
  ready: false
};

const locations = (state = initialState, action) => {
  switch (action.type) {
    case "PUT_LOCATIONS":
      state.locations = action.payload;
      return state;
    case "PUT_ME":
      state.me = action.payload;
      return state;
    case "PUT_PERMISSIONS":
      state.permissions = action.payload;
      return state;
    case "READY":
      state.ready = true;
      return state;
    default:
      return state;
  }
};

const store = createStore(locations);
// import { observable } from "mobx";
// import { createContext } from "react";

// const global = observable({
//   name: "",
//   email: "",
//   branch_id: 0,
//   access_token: "",
//   refresh_token: "",
//   locations: {}
// });
// const globalContext = createContext(global);
// globalContext.store = global;
// export default globalContext;

export default store;
