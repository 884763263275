import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { SUPPLIERS } from "GraphQL/Queries";
import { UPDATE_SUPPLIER, CREATE_SUPPLIER } from "GraphQL/Mutations";
import { List, Button, Form, Input, Modal, message } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import DomOutput from "Helpers/DomOutput";
import { FileAddOutlined } from "@ant-design/icons";

const EditModal = (props) => {
  const { visible, title, okText, onCancel, submit, initialValues, loading } =
    props;
  const { name = "", phone_number = "" } = initialValues;
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    submit(values);
  };

  const handleModalSubmit = () => {
    form.submit();
  };
  return (
    <Modal
      open={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      onOk={handleModalSubmit}
      confirmLoading={loading}
    >
      <Form layout="inline" onFinish={handleSubmit} form={form}>
        <Form.Item
          label="Name"
          name="name"
          initialValue={name}
          rules={[
            {
              required: true,
              message: "Name required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone No"
          name="phone_number"
          initialValue={phone_number}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Supplier = () => {
  const { data, error, loading, refetch } = useQuery(SUPPLIERS, {
    variables: { count: 99999999, page: 1 },
  });
  const [updateSupplier, {}] = useMutation(UPDATE_SUPPLIER);
  const [createSupplier, {}] = useMutation(CREATE_SUPPLIER);
  const [state, setState] = useState({
    modalVisible: false,
    modalTitle: "",
    modalOkText: "",
    editId: null,
    editValues: {},
    mode: "",
    submitLoading: false,
  });
  const {
    modalVisible,
    modalTitle,
    modalOkText,
    editId,
    mode,
    editValues,
    submitLoading,
  } = state;

  return (
    <React.Fragment>
      {modalVisible && (
        <EditModal
          visible={modalVisible}
          title={modalTitle}
          okText={modalOkText}
          initialValues={editValues}
          loading={submitLoading}
          submit={(v) => {
            setState({
              ...state,
              submitLoading: true,
            });
            if (mode === "edit") {
              updateSupplier({
                variables: {
                  id: editId,
                  name: v.name,
                  phone_number: v.phone_number,
                },
              })
                .then(() => {
                  setState({
                    ...state,
                    submitLoading: false,
                    modalVisible: false,
                  });
                })
                .catch((err) => {
                  message.error(err.message);
                  setState({
                    ...state,
                    modalVisible: false,
                  });
                });
            }

            if (mode === "new") {
              createSupplier({
                variables: {
                  name: v.name,
                  phone_number: v.phone_number,
                },
              })
                .then(() => {
                  return refetch();
                })
                .then(() => {
                  setState({
                    ...state,
                    submitLoading: false,
                    modalVisible: false,
                  });
                })
                .catch((err) => {
                  message.error(err.message);
                  setState({
                    ...state,
                    modalVisible: false,
                  });
                });
            }
          }}
          onCancel={() => {
            setState({
              ...state,
              modalVisible: false,
            });
          }}
        />
      )}
      <PageHeader
        extra={[
          <Button
            type="primary"
            icon={<FileAddOutlined />}
            key="addSupplier"
            onClick={() => {
              setState({
                ...state,
                modalVisible: true,
                mode: "new",
                modalTitle: "New supplier",
                modalOkText: "Add",
                editValues: {},
                submitLoading: false,
              });
            }}
          >
            Add
          </Button>,
        ]}
      />
      <br />
      <List
        bordered
        loading={loading}
        dataSource={loading ? [] : data.suppliers.data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type="primary"
                size="small"
                key={`edit${item.id}`}
                onClick={() => {
                  setState({
                    ...state,
                    modalVisible: true,
                    mode: "edit",
                    modalTitle: "Edit",
                    modalOkText: "Update",
                    editValues: item,
                    editId: item.id,
                    submitLoading: false,
                  });
                }}
              >
                Edit
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={item.name}
              description={`Phone: ${item.phone_number}`}
            />
          </List.Item>
        )}
      />
    </React.Fragment>
  );
};

export default Supplier;
