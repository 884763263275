import React, { useState } from "react";
import { Tabs } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import ByInvoice from "./ByInvoice";
import ByProduct from "./ByProduct";
import ByAgent from "./ByAgent";
import Products from "./Products";

const { TabPane } = Tabs;
const List = () => {
  return (
    <React.Fragment>
      <PageHeader title="Reports" />
      <Tabs>
        <TabPane tab="By Product" key="byproduct">
          <ByProduct />
        </TabPane>
        <TabPane tab="By Invoice" key="byinvoice">
          <ByInvoice />
        </TabPane>
        <TabPane tab="By Agent" key="byagent">
          <ByAgent />
        </TabPane>
        <TabPane tab="Products" key="products">
          <Products />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

export default List;
