import React from "react";
import { Typography, Alert, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { CREATE_PRODUCT } from "GraphQL/Mutations";
import ProductForm from "./ProductForm";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";

const { Text } = Typography;

const AddProduct = () => {
  const [createProduct, { data, loading, error }] = useMutation(CREATE_PRODUCT);

  const ProductFormWrapper = ProductForm;

  const submit = (value) => {
    value.category_id = Number(value.category_id);
    value.minqty = Number(value.minqty);
    value.weight = Number(value.weight);
    createProduct({ variables: value }).then((e) =>
      console.log("adasd", value)
    );
  };
  return (
    <React.Fragment>
      {error && <Alert message={error.message} type="error" showIcon />}
      {data && !loading && (
        <Alert
          message={
            <Text>
              Success created, Product ID:{" "}
              <Link to={`/products/${data.createProduct.id}/edit`}>
                {data.createProduct.id}
              </Link>
            </Text>
          }
          type="success"
          showIcon
        />
      )}
      <PageHeader title="Add new product" />
      <Spin spinning={loading}>
        <ProductFormWrapper submit={submit} />
      </Spin>
    </React.Fragment>
  );
};

export default AddProduct;
