import React, { useState } from "react";
import { TV } from "GraphQL/Queries";
import { UPDATE_TV, CREATE_TV } from "GraphQL/Mutations";
import { useQuery, useMutation } from "@apollo/client";
import {
  List,
  Button,
  Form,
  Modal,
  Input,
  Checkbox,
  Typography,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { FileAddOutlined } from "@ant-design/icons";
const { Text } = Typography;

const TvModal = (props) => {
  const {
    visible,
    onCancel,
    name,
    enable,
    editMode,
    submit,
    loading,
    title,
    okText,
  } = props;

  const [form] = Form.useForm()

  const handleSubmit = values => {
    submit(values);
};

  const handleModalSubmit = () =>{
    form.submit()
  }
  return (
    <Modal
      open={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      onOk={handleModalSubmit}
      confirmLoading={loading}
    >
      <Form form={form} layout="inline" onFinish={handleSubmit}>
        <Form.Item label="name" name="name" initialValue={name ? name : ""}
            rules={[
              {
                require: true,
                message: "name required",
              },
            ]}>
           <Input />
        </Form.Item>
        {editMode && (
          <Form.Item label="enable" name="enable" initialValue={enable} valuePropName="checked">
            <Checkbox />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

const Tv = () => {
  const { error, loading, data, refetch } = useQuery(TV, {
    variables: { first: 99999, page: 1 },
  });

  const [
    updateTv,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_TV, {
    refetchQueries: [`tv`],
    awaitRefetchQueries: true,
  });

  const [
    createTv,
    { data: createData, loading: createLoading, error: createError },
  ] = useMutation(CREATE_TV, {
    refetchQueries: "tv",
    awaitRefetchQueries: true,
  });

  const [state, setState] = useState({
    modalVisible: false,
    editId: null,
    name: "",
    editMode: false,
    modalLoading: false,
    enable: false,
  });
  const { modalVisible, editId, name, editMode, modalLoading, enable } = state;
  return (
    <React.Fragment>
      {modalVisible && (
        <TvModal
          visible={modalVisible}
          title={editMode ? "Edit TV" : "New TV"}
          name={name}
          enable={enable}
          okText={editMode ? "Update" : "Create"}
          editMode={editMode}
          onCancel={() => {
            setState({
              ...state,
              modalVisible: false,
            });
          }}
          loading={modalLoading}
          submit={(val) => {
            setState({
              ...state,
              modalLoading: true,
            });
            if (editMode) {
              updateTv({
                variables: { name: val.name, enable: val.enable, id: editId },
              }).then(() => {
                setState({
                  ...state,
                  modalLoading: false,
                  modalVisible: false,
                });
              });
            } else {
              createTv({ variables: { name: val.name } }).then(() => {
                refetch().then(() => {
                  setState({
                    ...state,
                    modalVisible: false,
                    modalLoading: false,
                  });
                });
              });
            }
          }}
        />
      )}

      <PageHeader
        extra={[
          <Button
            key="addTv"
            type="primary"
            icon={<FileAddOutlined />}
            onClick={() => {
              setState({
                ...state,
                editMode: false,
                modalVisible: true,
                name: "",
              });
            }}
          >
            Add
          </Button>,
        ]}
      />
      <br />
      <List
        size="small"
        bordered
        loading={loading}
        dataSource={loading ? [] : data.tvData.data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                size="small"
                key={item.id}
                type="primary"
                onClick={() => {
                  setState({
                    ...state,
                    modalVisible: true,
                    editId: item.id,
                    enable: item.enable,
                    name: item.name,
                    editMode: true,
                  });
                }}
              >
                Edit
              </Button>,
            ]}
          >
            <List.Item.Meta
              description={
                <Text disabled={!item.enable}>
                  {item.name} {item.enable ? "" : "(Disabled)"}
                </Text>
              }
            />
          </List.Item>
        )}
      />
    </React.Fragment>
  );
};

export default Tv;
