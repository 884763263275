import React from "react";
import { useQuery } from "@apollo/client";
import { TRANSACTION_COUNT, PRODUCTS } from "GraphQL/Queries";
import { Descriptions, Spin } from "antd";
import { useSelector } from "react-redux";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_OWNER } from "constants/roles";

const Dashboard = () => {
  const permissions = useSelector((state) => state.permissions);
  const me = useSelector((state) => state.me);
  const countOptions = {
    fetchPolicy: "cache-and-network",
  };
  if (me.branch.id !== 1) {
    countOptions.variables = {
      branch_id: me.branch.id,
    };
  }
  const { data, loading, error } = useQuery(TRANSACTION_COUNT, countOptions);
  const { data: insufficientStockData, loading: insufficientStockLoading } =
    useQuery(PRODUCTS, {
      fetchPolicy: "cache-and-network",
      variables: {
        page: 0,
        count: 999999999,
        insufficient_stock_only: true,
        is_active: true,
      },
    });
  return (
    <Descriptions bordered title="Dashboard" size="small">
      {permissions.CAN_VIEW_TRANSACTION_SELL &&
        [ROLE_OWNER, ROLE_ADMIN, ROLE_SUPER_ADMIN].includes(
          Number(me.role.id)
        ) && (
          <Descriptions.Item label="Pending transactions">
            <Spin spinning={loading}>
              {loading
                ? "0 transactions"
                : data.transactionCount.count + " transactions"}
            </Spin>
          </Descriptions.Item>
        )}
      {permissions.CAN_VIEW_PRODUCT &&
        [ROLE_OWNER, ROLE_ADMIN, ROLE_SUPER_ADMIN].includes(
          Number(me.role.id)
        ) && (
          <Descriptions.Item label="Insufficents stocks">
            <Spin spinning={insufficientStockLoading}>
              {insufficientStockLoading
                ? "0 products"
                : insufficientStockData.products
                ? insufficientStockData.products.data.length + " products"
                : "0 products"}
            </Spin>
          </Descriptions.Item>
        )}
    </Descriptions>
  );
};

export default Dashboard;
