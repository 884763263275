import { gql } from '@apollo/client';

export const BRANCHES = gql`
  query($count: Int!, $page: Int!, $include_inactive: Boolean) {
    branches(count: $count, page: $page, include_inactive: $include_inactive) {
      paginationInfo {
        total
        lastPage
      }
      data {
        id
        email
        name
        phone_number
        address
        is_active
      }
    }
  }
`;

export const BRANCH = gql`
  query($id: ID!) {
    branch(id: $id) {
      id
      email
      is_active
      is_branchable
      name
      phone_number
      address
      province
      district
      sub_district
      postal_code
      created_at
      updated_at
    }
  }
`;
