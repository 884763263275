import React from "react";
import { Select, Spin } from "antd";
import { ME, BRANCHES } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";

const BranchSelect = React.forwardRef((props, ref) => {
  const { hideall = false, hideSelf = false, defaultValue, ...rest } = props;
  const me = useSelector((state) => state.me);
  const {
    data: branchesData,
    loading: branchesLoading,
    error: branchesError,
  } = useQuery(BRANCHES, {
    fetchPolicy: "cache-and-network",
    variables: { count: 1000000, page: 1 },
  });
  if (branchesLoading) {
    return <Spin spinning={branchesLoading} size="small" />;
  }
  let branches = branchesData.branches.data;
  if (hideSelf) {
    branches = branchesData.branches.data.filter(
      (branch) => branch.id !== me.branch.id
    );
  }
  return (
    <Select
      {...props}
      ref={ref}
      style={{ minWidth: 150 }}
      defaultValue={
        defaultValue ? defaultValue : hideall ? me.branch.id : "all"
      }
      optionFilterProp="children"
      showSearch
    >
      {!hideall && <Select.Option value="all">All</Select.Option>}
      {branches.map((branch) => (
        <Select.Option key={branch.id} value={Number(branch.id)}>
          {branch.name} {branch.id === me.branch.id ? "(current)" : ""}
        </Select.Option>
      ))}
    </Select>
  );
});

export default BranchSelect;
