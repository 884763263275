import React, { useState } from "react";
import UserForm from "Pages/Users/UserForm";
import { CREATE_USER } from "GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import { message, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { Redirect } from "react-router-dom";
const UserAdd = props => {
  const [redirect, setRedirect] = useState(false);
  const { branch_id } = props.match.params;
  const [createUser, { data, error, loading }] = useMutation(CREATE_USER);
  const handleSubmit = v => {
    v.branch_id = branch_id;
    createUser({ variables: {
      ...v,
      role_id: Number(v.role_id),
      branch_id: Number(v.branch_id),
    } })
      .then(() => {
        message.success("User created");
        setRedirect(true);
      })
      .catch(err => message.error(err.message));
  };
  if (redirect) {
    return <Redirect to={`/branches/${branch_id}/users`} />;
  }
  return (
    <React.Fragment>
      <PageHeader title="New user" />
      <Spin spinning={loading}>
        <UserForm initialValues={{}} editMode={false} submit={handleSubmit} />
      </Spin>
    </React.Fragment>
  );
};

export default UserAdd;
