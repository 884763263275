import React, { useState } from "react";
import UserForm from "./UserForm";
import { Spin, message } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { CREATE_USER } from "GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
const Add = () => {
  const [redirect, setRedirect] = useState(false);
  const me = useSelector(state => state.me);
  const [createUser, { loading }] = useMutation(CREATE_USER);
  const handleSubmit = v => {
    v.branch_id = me.branch.id;
    createUser({ variables: v })
      .then(() => {
        setRedirect(true);
        message.success("User created");
      })
      .catch(err => message.error(err.message));
  };
  if (redirect) {
    return <Redirect to="/users" />;
  }
  return (
    <React.Fragment>
      <PageHeader title="Add user" />
      <br />
      <Spin spinning={loading}>
        <UserForm initialValues={{}} submit={handleSubmit} />
      </Spin>
    </React.Fragment>
  );
};

export default Add;
