import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import GlobalStore from "Store/Global";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import apolloClient from "GraphQL/apollo";

import { QueryClientProvider, QueryClient } from "react-query";

const queryClient = new QueryClient();

window.idCurrency = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
});
window.idCurrency.parse = function (str) {
  let separator = new Intl.NumberFormat("id-ID").format(1111).replace(/1/g, "");
  return str.replace(new RegExp("\\" + separator, "g"), "");
};

window.idCurrency.formatSimple = function (value) {
  return `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

window.idCurrency.parseSimple = function (value) {
  return value.replace(/\Rp\s?|(\.*)/g, "");
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={GlobalStore}>
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </ApolloProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
