import React, { useEffect, useState } from "react";
import { Descriptions, Spin, Typography } from "antd";
import { ROLE_PERMISSION } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
const { Text } = Typography;
const RolePermission = props => {
  const { role_id } = props;
  const { data, error, loading, refetch } = useQuery(ROLE_PERMISSION, {
    variables: { role_id: Number(role_id) }
  });

  if (!role_id) {
    return (
      <Descriptions>
        <Descriptions.Item label="permitted">unavailable</Descriptions.Item>
      </Descriptions>
    );
  }
  if (loading) {
    return <Spin spinning={loading} />;
  }
  return (
    <Descriptions size="small" bordered layout="vertical">
      {data.RolePermission.permissions.map(permission => (
        <Descriptions.Item key={permission.id} span={2} label={permission.name}>
          {permission.permitted ? (
            <Text>YES</Text>
          ) : (
            <Text type="secondary">NO</Text>
          )}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default RolePermission;
