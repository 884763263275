import React from "react";
import { BRANCH } from "GraphQL/Queries";
import { UPDATE_BRANCH } from "GraphQL/Mutations";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, message } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import BranchForm from "./BranchForm";
const Edit = props => {
  const { id } = props.match.params;
  const { data, loading, error } = useQuery(BRANCH, { variables: { id: id } });
  const [updateBranch, { loading: loadingUpdate }] = useMutation(UPDATE_BRANCH);
  if (loading) {
    return <Spin spinning={loading} />;
  }
  const handleSubmit = v => {
    v.id = id;
    updateBranch({ variables: v })
      .then(() => {
        message.success("Branch updated");
      })
      .catch(err => message.error(err.message));
  };
  return (
    <React.Fragment>
      <PageHeader title="Edit branch" />
      <Spin spinning={loadingUpdate}>
        <BranchForm initialValues={data.branch} submit={handleSubmit} />
      </Spin>
    </React.Fragment>
  );
};
export default Edit;
