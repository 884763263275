import React, { useState } from "react";
import CustomerSelect from "UI/Input/CustomerSelect";
import TransactionForm from "./TransactionForm";
import { CREATE_TRANSACTION_SELL } from "GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import { Spin, Alert, message } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { Redirect } from "react-router-dom";

const showTransactionCreated = () => {
  message.success("Transaction created");
};

const Add = (props) => {
  const [state, setState] = useState({
    customerModalOpen: false,
    customerEditModalOpen: false,
    customer: {},
    items: [],
  });

  const [createTransactionSell, { error, loading, data }] = useMutation(
    CREATE_TRANSACTION_SELL
  );
  const { customerModalOpen, customerEditModalOpen, customer, items } = state;
  const addItem = (item, qty) => {
    let found = false;
    for (let i = 0; i < items.length; i++) {
      if (item.id === items[i].id) {
        found = i + "";
        break;
      }
    }
    if (found) {
      items[found].qty += qty;
    } else {
      const newItem = {
        ...item,
        qty,
      };
      items.push(newItem);
    }
    setState({
      ...state,
      items,
    });
  };

  const deleteItem = (id) => {
    let found = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === id) {
        found = i + "";
      }
    }
    if (found) {
      items.splice(found, 1);
    }

    setState({
      ...state,
      items,
    });
  };
  const generateTransactionData = (values) => {
    const data = {
      branch_id: Number(values.branch_id),
      buyer_id: Number(values.customer_id),
      courier_id: Number(values.courier_id),
      tv_id: Number(values.tv_id),
      shipping_cost: values.shipping_cost,
      address: customer.address,
      province: customer.province,
      district: customer.district,
      sub_district: customer.sub_district,
      phone_number: customer.phone_number,
      postal_code: customer.postal_code,
      coupon_id: Number(values.coupon_id),
      inventories: {
        create: [],
      },
    };

    for (let i = 0; i < items.length; i++) {
      data.inventories.create.push({
        product_id: Number(items[i].id),
        quantity: items[i].qty,
        price: items[i].price,
      });
    }
    return data;
  };
  const submit = (values) => {
    createTransactionSell({ variables: generateTransactionData(values) }).then(
      () => showTransactionCreated()
    );
  };

  if (data) {
    return <Redirect to="/transactions" />;
  }

  return (
    <React.Fragment>
      <PageHeader title="New transaction" />
      {error && <Alert message={error.message} type="error" showIcon />}
      {data && <Alert message="Success created" type="success" showIcon />}
      <Spin spinning={loading}>
        <CustomerSelect
          visible={customerModalOpen}
          submit={(customer) =>
            setState({
              ...state,
              customerModalOpen: !customerModalOpen,
              customer,
            })
          }
          onCancel={() =>
            setState({ ...state, customerModalOpen: !customerModalOpen })
          }
        />
        <CustomerSelect.Edit
          initialValues={customer}
          visible={customerEditModalOpen}
          submit={(customer) =>
            setState({
              ...state,
              customerEditModalOpen: !customerEditModalOpen,
              customer,
            })
          }
          onCancel={() =>
            setState({
              ...state,
              customerEditModalOpen: !customerEditModalOpen,
            })
          }
        />
        <TransactionForm
          submit={submit}
          deleteItem={deleteItem}
          addItem={addItem}
          items={items}
          setCustomerModalOpen={(val) => {
            setState({ ...state, customerModalOpen: val });
          }}
          customerModalOpen={customerModalOpen}
          setCustomerEditModalOpen={(val) => {
            setState({ ...state, customerEditModalOpen: val });
          }}
          customerEditModalOpen={customerEditModalOpen}
          customer={customer}
        />
      </Spin>
    </React.Fragment>
  );
};

export default Add;
