import { useQuery } from "@apollo/client";
import { COUPONS } from "GraphQL/Queries";
import { List, Typography, Button, Tag } from "antd";
import { formatDate } from "Utils/Date";
const { Text } = Typography;

const CouponSelect = ({ onSelect }) => {
  const { data, loading, error } = useQuery(COUPONS, {
    fetchPolicy: "cache-and-network",
  });
  return (
    <div>
      <List
        loading={loading}
        dataSource={data ? data.coupons : []}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            actions={[
              <Button
                key="select"
                onClick={() => onSelect(item)}
                disabled={item.is_expired}
              >
                Select
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <Text disabled={item.is_expired | !item.enable}>
                  {item.key} - {item.name}{" "}
                  <Tag color="green">
                    {item.amount_type === "FIXED" &&
                      `-${window.idCurrency.format(item.amount)}`}
                    {item.amount_type === "PERCENTAGE" &&
                      `-${item.amount}% off`}
                  </Tag>
                </Text>
              }
              description={
                <Text disabled={item.is_expired}>
                  <div>
                    <div>
                      {formatDate(item.start_period)} to&nbsp;
                      {formatDate(item.end_period)}
                    </div>
                    <div>
                      {item.description ? (
                        item.description
                      ) : (
                        <em>No description</em>
                      )}
                    </div>
                  </div>
                </Text>
              }
            ></List.Item.Meta>
          </List.Item>
        )}
      />
    </div>
  );
};
export default CouponSelect;
