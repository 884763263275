import React from "react";
import { USERS } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { Spin, Table, Button } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FileAddOutlined } from "@ant-design/icons";
const UserList = props => {
  const me = useSelector(state => state.me);
  const permissions = useSelector(state => state.permissions);
  const { branch_id } = props.match.params;
  const { data, loading, error } = useQuery(USERS, {
    fetchPolicy: "cache-and-network",
    variables: { first: 9999999, page: 1, branch_id: Number(branch_id) }
  });
  if (loading) {
    return <Spin spinning={loading} />;
  }
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <React.Fragment>
          <span>{record.name}</span> <br /> <span>{record.role.name}</span>
        </React.Fragment>
      )
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text, record) => (
        <React.Fragment>
          <span>{record.phone_number}</span>
          <br />
          <span>{record.email}</span>
        </React.Fragment>
      )
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <React.Fragment>
          {permissions.CAN_UPDATE_USER && me.branch.id === 1 && (
            <Link to={`/branches/${branch_id}/users/${record.id}/edit`}>
              Edit
            </Link>
          )}
        </React.Fragment>
      )
    }
  ];
  return (
    <React.Fragment>
      <PageHeader
        title={`Users on branch ${branch_id}`}
        extra={
          permissions.CAN_UPDATE_USER &&
          me.branch.id === 1 && [
            <Link to={`/branches/${branch_id}/users/add`} key="add">
              <Button key="addUser" type="primary" icon={<FileAddOutlined />}>
                Add
              </Button>
            </Link>
          ]
        }
      />
      <Table
        dataSource={data.users.data}
        columns={columns}
        rowKey={record => record.id}
      />
    </React.Fragment>
  );
};

export default UserList;
