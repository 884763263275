import React from "react";
import { Form, Input, Checkbox, Button, InputNumber } from "antd";
import CategorySelect from "UI/Input/CategorySelect";

const ProductForm = (props) => {
  const [form] = Form.useForm();
  const { submit, initialValues = {}, editMode = false } = props;
  const { category_id, name, weight, price, minqty, description, is_active } =
    initialValues;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 3,
      },
    },
  };

  const handleSubmit = (values) => {
    submit(values);
    if (!editMode) {
      form.resetFields();
    }
  };
  return (
    <Form
      form={form}
      layout="horizontal"
      {...formItemLayout}
      onFinish={handleSubmit}
    >
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[{ required: true, message: "Name is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Category"
        name="category_id"
        rules={[{ required: true, message: "Choose category" }]}
        initialValue={category_id ? Number(category_id) : ""}
      >
        <CategorySelect hideall={true} />
      </Form.Item>
      <Form.Item
        label="Weight"
        name="weight"
        initialValue={weight ? weight : 0}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        label="price"
        name="price"
        initialValue={price ? price : 0}
        rules={[{ required: true, message: "Price is required" }]}
      >
        <InputNumber
          style={{ width: "100%" }}
          onChange={(e) => console.log(e)}
          min={0}
          formatter={(value) => window.idCurrency.formatSimple(value)}
          parser={(value) => window.idCurrency.parseSimple(value)}
        />
      </Form.Item>
      <Form.Item
        label="Min qty"
        name="minqty"
        initialValue={minqty ? minqty : 0}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        label="description"
        name="description"
        initialValue={description}
      >
        <Input.TextArea></Input.TextArea>
      </Form.Item>
      <Form.Item
        label="Activate"
        name="is_active"
        initialValue={is_active === false ? 0 : 1}
        valuePropName="checked"
      >
        <Checkbox></Checkbox>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProductForm;
