import React, { useContext, useState } from "react";
import axios from 'axios';
import { Form, Input, Button, Spin, Typography, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Redirect } from "react-router-dom";
const { Title } = Typography;

const NormalLoginForm = props => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleSubmit = async(values) => {
    const form = new FormData();
    form.append('grant_type', 'password');
    form.append('username', values.username);
    form.append('password', values.password);
    form.append('client_id', process.env.REACT_APP_SERVER_API_CLIENT_ID);
    form.append('client_secret', process.env.REACT_APP_SERVER_API_CLIENT_SECRET);
    setLoading(true);
    try{
      setError(null);
      const data = await axios.post(process.env.REACT_APP_SERVER_API_URL + "/oauth/token", form).then(response => response.data)
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      setLoggedIn(true);
    }
    catch(err){
      console.log('error', JSON.stringify(err.response.data))
      setError(err.response.data.error_description);
      setLoading(false);
    }
  };

  if (loggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <>
    {error && (
      <Alert
        message="Error"
        description={error}
        type="error"
        showIcon
      />
    )}
    <Spin spinning={loading}>
      <div
        id="container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <Form onFinish={handleSubmit} className="login-form">
          <Form.Item>
            <Title level={4}>Berkah</Title>
          </Form.Item>
          <Form.Item name="username" rules={[
                { required: true, message: "Please input your username!" }
              ]}>
              <Input
                prefix={
                  <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Username"
              />
          </Form.Item>
          <Form.Item name="password" rules={[
                { required: true, message: "Please input your Password!" }
              ]}>
              <Input
                prefix={
                  <LockOutlined type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Password"
              />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
    </>
  );
};

export default NormalLoginForm;
