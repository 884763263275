import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Spin, Alert, message } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { Redirect } from "react-router-dom";
import BuyForm from "./BuyForm";
import { useSelector } from "react-redux";
import { CREATE_TRANSACTION_BUY_TO_SUPPLIER } from "GraphQL/Mutations";
const Add = props => {
  const branchId = useSelector(state => state.me.branch.id);
  const [state, setState] = useState({
    items: []
  });
  const { items } = state;

  const [createBuy, { data, error, loading }] = useMutation(
    CREATE_TRANSACTION_BUY_TO_SUPPLIER
  );

  const addItem = (item, qty) => {
    let found = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === item.id) {
        found = "" + i;
      }
    }
    if (found) {
      items[found].qty += qty;
    } else {
      item.qty = qty;
      items.push(item);
    }
    setState({
      ...state,
      items
    });
  };

  const deleteItem = id => {
    let found = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === id) {
        found = "" + i;
      }
    }
    items.splice(found, 1);
    setState({
      ...state,
      items
    });
  };

  const submit = values => {
    const variables = {
      branch_id: branchId,
      supplier_id: values.supplier_id,
      courier_id: values.courier_id,
      inventories: []
    };
    for (let i = 0; i < items.length; i++) {
      variables.inventories.push({
        product_id: items[i].id,
        quantity: items[i].qty,
        price: items[i].price
      });
    }

    createBuy({ variables }).then(data => {
      message.success("Buy created");
    });
  };

  if (data) {
    return <Redirect to="/buy" />;
  }
  return (
    <React.Fragment>
      <PageHeader title="New buy" />
      {error && <Alert message={error.message} type="error" showIcon />}
      <Spin spinning={loading}>
        <BuyForm
          branchId={branchId}
          addItem={addItem}
          items={items}
          deleteItem={deleteItem}
          submit={submit}
        />
      </Spin>
    </React.Fragment>
  );
};

export default Add;
