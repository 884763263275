import { gql } from '@apollo/client';

export const UPDATE_TV = gql`
  mutation ($id: ID!, $name: String!, $enable: Boolean!) {
    updateTv(id: $id, name: $name, enable: $enable) {
      id
      name
      enable
      created_at
      updated_at
    }
  }
`;

export const CREATE_TV = gql`
  mutation ($name: String!) {
    createTv(name: $name) {
      id
      name
      created_at
      updated_at
    }
  }
`;
