import React from "react";
import { Button, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { BRANCHES } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { Table, Spin, Divider } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FileAddOutlined } from "@ant-design/icons";
const List = () => {
  const me = useSelector((state) => state.me);
  const permissions = useSelector((state) => state.permissions);
  const { data, loading, error } = useQuery(BRANCHES, {
    variables: { count: 999999, page: 1, include_inactive: true },
    fetchPolicy: "cache-and-network",
  });
  if (loading) {
    return <Spin spinning={loading} />;
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <React.Fragment>
          <span>
            {record.name}{" "}
            {record.is_active ? "" : <Tag color="orange">Inactive</Tag>}
          </span>
          <br />
          <span>{record.phone_number}</span>
          <br />
          <span>{record.email}</span>
        </React.Fragment>
      ),
    },
    {
      title: "Actions",
      dataIndex: "edit",
      key: "edit",
      render: (text, record) => (
        <React.Fragment>
          {permissions.CAN_UPDATE_BRANCH && me.branch.id === 1 && (
            <Link
              to={`/branches/${record.id}/edit`}
              key={`edit${record.id}`}
              type="primary"
            >
              Edit
            </Link>
          )}
          <Divider type="vertical" />
          <Link
            to={`/branches/${record.id}/users`}
            key={`user${record.id}`}
            type="primary"
          >
            Users
          </Link>
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <PageHeader
        title="Branches"
        extra={
          permissions.CAN_UPDATE_BRANCH &&
          me.branch.id === 1 && [
            <Link to="/branches/add">
              <Button key="addbranch" icon={<FileAddOutlined />} type="primary">
                Add
              </Button>
            </Link>,
          ]
        }
      />
      <br />
      <Table
        pagination={false}
        rowKey={(record) => record.id}
        dataSource={data.branches.data}
        columns={columns}
      />
    </React.Fragment>
  );
};

export default List;
