import { gql } from '@apollo/client';

export const STOCK_MIGRATIONS = gql`
  query(
    $buyer_id: Int
    $agent_id: Int
    $product_id: Int
    $start_date: Date
    $end_date: Date
    $page: Int!
    $count: Int!
  ) {
    migrateStockList(
      buyer_id: $buyer_id
      agent_id: $agent_id
      product_id: $product_id
      start_date: $start_date
      end_date: $end_date
      page: $page
      count: $count
    ) {
      paginationInfo {
        total
        lastPage
      }
      data {
        id
        invoice_number
        created_at
        seller {
          id
          name
        }
        buyer {
          id
          name
        }
        agent {
          id
          name
          branch {
            id
            name
          }
        }
        inventories {
          id
          quantity
          product {
            id
            name
          }
        }
      }
    }
  }
`;
