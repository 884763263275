import React, { useState } from "react";
import { Table, Button, Divider, Form, Input } from "antd";
import { CUSTOMERS } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import Locations from "Helpers/Locations";
const Select = (props) => {
  const { submit } = props;
  const [state, setState] = useState({
    page: 1,
    keyword: null,
    province: null,
    district: null,
    sub_district: null,
  });

  const { data, loading, error } = useQuery(CUSTOMERS, {
    fetchPolicy: "cache-and-network",
    variables: { page: state.page, first: 10, keyword: state.keyword },
  });

  let pageTotal = 0;
  if (!loading) {
    pageTotal = data.customers.paginationInfo.total;
  }

  const handleSelect = (values) => {
    submit(values);
  };
  const columns = [
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text, record) => (
        <div>
          {record.name}&nbsp;
          <Divider type="vertical" />
          &nbsp;
          <a href={`tel:${record.phone_number}`}>{record.phone_number}</a>&nbsp;
          {record.phone_number_2 && (
            <span>
              {" "}
              / <a href={`tel:${record.phone_number}`}>{record.phone_number}</a>
            </span>
          )}
          <a href={`mailto:${record.email}`}>{record.email}</a>
          <br />
          <span>
            {record.sub_district}, {Locations.getCaption(record.district)}
          </span>
          <br />
          <span>{Locations.getCaption(record.province)}</span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button size="small" onClick={() => handleSelect(record)}>
          Select
        </Button>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Form layout="inline">
        <Form.Item label="Search">
          <Input
            type="text"
            allowClear
            placeholder="eg. Tony"
            onPressEnter={(e) => {
              console.log(e);
              setState({
                ...state,
                keyword: e.target.value,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
      </Form>
      <Table
        size="small"
        columns={columns}
        rowKey={(record) => record.id}
        pagination={{
          total: pageTotal,
          onChange: (page, pageSize) =>
            setState({ ...state, pageNumber: page }),
        }}
        loading={loading}
        dataSource={loading ? [] : data.customers.data}
      />
    </React.Fragment>
  );
};

export default Select;
