import { gql } from "@apollo/client";

export const REPORT_BY_PRODUCT = gql`
  query (
    $branch_id: Int
    $tv_id: Int
    $date_from: Date
    $date_to: Date
    $products_id: [Int]
    $status: TransactionStatus
  ) {
    productTransaction(
      products_id: $products_id
      branch_id: $branch_id
      tv_id: $tv_id
      date_from: $date_from
      date_to: $date_to
      status: $status
    ) {
      data {
        id
        created_at
        products {
          id
          name
          inventories {
            id
            transaction_id
            product_id
            quantity
            price
            transaction {
              id
              invoice_number
              seller {
                id
                name
              }
              tv {
                id
                name
              }
            }
            created_at
            updated_at
          }
        }
      }
    }
  }
`;

export const REPORT_BY_INVOICE = gql`
  query (
    $date_from: Date
    $date_to: Date
    $status: TransactionStatus
    $tv_id: Int
    $branch_id: Int
  ) {
    invoiceTransaction(
      date_from: $date_from
      date_to: $date_to
      status: $status
      tv_id: $tv_id
      branch_id: $branch_id
    ) {
      data {
        created_at
        transactions {
          id
          invoice_number
          seller_id
          buyer_id
          agent_id
          tv_id
          courier_id
          shipping_cost
          additional_fee
          status
          grandtotal
          price_total
          created_at
          updated_at
          description
          commision
          address
          province
          district
          sub_district
          postal_code
          phone_number
          coupon {
            id
            name
            key
            type
            amount_type
            amount
            start_period
            end_period
            description
            enable
            is_expired
            created_at
            updated_at
          }
          seller {
            id
            name
          }
          courier {
            id
            name
            created_at
            updated_at
          }
          tv {
            id
            name
            created_at
            updated_at
          }
          inventories {
            id
            quantity
            price
            product {
              id
              name
            }
            created_at
            updated_at
          }
        }
      }
    }
  }
`;

export const REPORT_BY_AGENT = gql`
  query (
    $start_year: Int
    $start_month: Int
    $end_year: Int
    $end_month: Int
    $branch_id: Int
  ) {
    agentTransaction(
      start_year: $start_year
      start_month: $start_month
      end_year: $end_year
      end_month: $end_month
      branch_id: $branch_id
    ) {
      data {
        created_at
        agents {
          id
          name
          quantity
          transactions
        }
      }
    }
  }
`;
