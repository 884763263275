export const processCoupon = (coupon, price) => {
  let cut = 0;
  if (coupon) {
    if (coupon.amount_type === "FIXED") {
      cut = coupon.amount;
      price = price - coupon.amount;
    }
    if (coupon.amount_type === "PERCENTAGE") {
      cut = price * (coupon.amount / 100);
      price = price - cut;
    }
  }
  return {
    cut,
    price,
  };
};
