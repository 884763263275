import React from "react";
import UserForm from "Pages/Users/UserForm";
import { USER } from "GraphQL/Queries";
import { UPDATE_USER } from "GraphQL/Mutations";
import { Spin, message } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useQuery, useMutation } from "@apollo/client";
const UserEdit = props => {
  const { user_id } = props.match.params;
  const { data, error, loading } = useQuery(USER, {
    variables: { id: user_id }
  });
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER);
  if (loading) {
    return <Spin spinning={loading} />;
  }
  const handleSubmit = v => {
    let data = v;
    if (v.password === "") {
      const { password, ...rest } = v;
      data = rest;
    }
    data.role_id = Number(data.role_id);
    data.id = Number(user_id);
    if (data.tv_id) {
      data.tv_id = Number(data.tv_id);
    }
    updateUser({ variables: data })
      .then(() => message.success("updated"))
      .catch(err => message.error(err.message));
  };

  const initialValues = {...data.user}

  data.user.meta.forEach(meta => {
    if(meta.key === "tv_id"){
      initialValues.tv_id = meta.value
    }
  })

  return (
    <React.Fragment>
      <PageHeader title="Edit User" />
      <Spin spinning={loading || updateLoading}>
        <UserForm editMode initialValues={initialValues} submit={handleSubmit} />
      </Spin>
    </React.Fragment>
  );
};

export default UserEdit;
