import React from "react";
import { Row, Col, List, Button, Typography, Tag } from "antd";
import ProductSelect from "./ProductSelect";
import { processCoupon } from "Utils/Coupon";
const { Text } = Typography;
const ItemsInput = (props) => {
  const { add, items, deleteItem, branchId, hidePrice = false, coupon } = props;
  return (
    <Row gutter={16}>
      <Col span={12}>
        <ProductSelect add={add} branchId={branchId} />
      </Col>
      <Col span={12}>
        <Text>Cart</Text>
        <br />
        <List
          bordered
          itemLayout="horizontal"
          dataSource={items}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              actions={[
                <Button key="remove-item" onClick={() => deleteItem(item.id)}>
                  delete
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={item.name}
                description={
                  <div>
                    qty: {item.qty}
                    {hidePrice ? (
                      ""
                    ) : (
                      <div>
                        {coupon ? (
                          <div>
                            {" "}
                            {coupon.amount_type === "PERCENTAGE" ? (
                              <>
                                <Text>
                                  {window.idCurrency.format(
                                    processCoupon(coupon, item.price * item.qty)
                                      .price
                                  )}
                                </Text>
                                <Text delete>
                                  {window.idCurrency.format(
                                    item.price * item.qty
                                  )}
                                </Text>{" "}
                                <Tag color="green">
                                  {window.idCurrency.format(
                                    processCoupon(coupon, item.price * item.qty)
                                      .cut
                                  )}{" "}
                                  off
                                </Tag>
                              </>
                            ) : (
                              <Text>
                                {window.idCurrency.format(
                                  item.price * item.qty
                                )}
                              </Text>
                            )}
                          </div>
                        ) : (
                          window.idCurrency.format(item.price * item.qty)
                        )}
                        <div></div>
                      </div>
                    )}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default ItemsInput;
