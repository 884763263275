import React, { useState } from "react";
import { Table, Button, Form, Input } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { Link } from "react-router-dom";
import { CUSTOMERS } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import Locations from "Helpers/Locations";
import LocationSelectMini from "UI/Input/LocationSelectMini";
import { useSelector } from "react-redux";
import { FileAddOutlined } from "@ant-design/icons";
const List = () => {
  const me = useSelector((state) => state.me);
  const permissions = useSelector((state) => state.permissions);
  const [state, setState] = useState({
    pageNumber: 1,
    keyword: null,
    province: null,
    district: null,
  });
  const { pageNumber, keyword, province, district } = state;
  const { data, loading, error } = useQuery(CUSTOMERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      keyword: keyword,
      page: pageNumber,
      first: 10,
      province: province,
      district: district,
    },
  });
  let pageTotal = 0;
  if (!loading) {
    pageTotal = data.customers.paginationInfo.total;
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text, record) => (
        <span>
          <a href={`phone:${record.phone_number}`}>{record.phone_number}</a>
          {record.phone_number_2 && (
            <span>
              {" "}
              /{" "}
              <a href={`phone:${record.phone_number_2}`}>
                {record.phone_number_2}
              </a>
            </span>
          )}
          <br />
          <a href={`mailto:${record.email}`}>{record.email}</a>
        </span>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) => (
        <span>
          <span>{record.sub_district}</span>
          <br />
          <span>{Locations.getCaption(record.district)}</span>
          <br />
          <span>{Locations.getCaption(record.province)}</span>
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <span>
          {permissions.CAN_UPDATE_CUSTOMER && me.branch.id === 1 && (
            <Link to={`/customers/${record.id}/edit`}>Edit</Link>
          )}
        </span>
      ),
    },
  ];

  const onLocationSelect = (value) => {
    setState({
      ...state,
      province: value[0],
      district: value[1],
    });
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Customers"
        extra={
          permissions.CAN_UPDATE_CUSTOMER &&
          me.branch.id === 1 && [
            <Link to="/customers/add" key="add">
              <Button type="primary" icon={<FileAddOutlined />}>
                {" "}
                Add
              </Button>
            </Link>,
          ]
        }
      />
      <Form layout="inline">
        <Form.Item label="Search">
          <Input
            type="text"
            allowClear
            placeholder="eg. Tony"
            onPressEnter={(e) => {
              console.log(e);
              setState({
                ...state,
                keyword: e.target.value,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Location">
          <LocationSelectMini onChange={onLocationSelect}></LocationSelectMini>
        </Form.Item>
      </Form>
      <Table
        pagination={{
          total: pageTotal,
          onChange: (page, pageSize) =>
            setState({ ...state, pageNumber: page }),
        }}
        columns={columns}
        rowKey={(record) => record.id}
        loading={loading}
        dataSource={loading ? [] : data.customers.data}
        expandable={{
          expandedRowRender: (record) => <p>{record.address}</p>,
        }}
      />
    </React.Fragment>
  );
};

export default List;
