import React, { useState } from "react";
import { Form, Input, Checkbox, Collapse, Button } from "antd";
import RoleSelect from "UI/Input/RoleSelect";
import TVSelect from "UI/Input/TvSelect";
import RolePermission from "UI/Display/RolePermission";
const { Panel } = Collapse;
const UserForm = props => {
  const { initialValues, submit, editMode = false } = props;
  const { name, email, phone_number, role, is_active, tv_id } = initialValues;
  const [ form ] = Form.useForm();
  const handleSubmit = values => {
      submit(values);
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  const [state, setState] = useState({
    role_id: role ? role.id : null
  });
  const { role_id } = state;

  return (
    <Form onFinish={handleSubmit} {...formItemLayout} form={form}>
      <Form.Item label="Name" name="name"  initialValue={name} 
          rules={[
            {
              required: true
            }
          ]}>
         <Input />
      </Form.Item>
      <Form.Item label="Email" name="email" initialValue={email}
          rules={[{ required: !editMode, message: "Email required for login" }]}>
        <Input readOnly={editMode} />
      </Form.Item>
      <Form.Item label="Phone" name="phone_number" initialValue={phone_number}
          rules={[{ required: true, message: "Phone number required" }]}>
         <Input />
      </Form.Item>
      <Form.Item label="password" name="password" rules={[{ required: !editMode, message: "password required" }]}> 
         <Input type="password" placeholder="Password" />
      </Form.Item>
      <Form.Item label="Confirm" name="confirm_password" rules={[
            {
              required: !editMode,
              message: "Please confirm your password!"
            },
            {
              validator: (rule, value, callback) => {
                if (value === form.getFieldValue("password")) {
                  callback();
                } else {
                  callback("Password confimation error");
                }
              }
            }
          ]}>
        <Input type="password" />
      </Form.Item>

      <Form.Item label="Can login" name="is_active" valuePropName="checked"  initialValue={editMode ? is_active : true}>
        <Checkbox>Active</Checkbox>
      </Form.Item>
      <Form.Item label="Role" name="role_id"  initialValue={role ? role.id : ""}
          rules={[{ required: true, message: "Role is required" }]}>
          <RoleSelect
            onChange={val => {
              setState({
                ...state,
                role_id: val
              });
            }}
          />
      </Form.Item>
      <Form.Item
        label="TV"
        help="* Only for 'TV' role"
        name="tv_id"
        initialValue={tv_id ? tv_id : null}
      >
        <TVSelect
            onChange={val => {
              setState({
                ...state,
                tv_id: val
              });
            }}
          />
      </Form.Item>
      <Form.Item label="Permissions">
        <Collapse>
          <Panel header="View permissions">
            <RolePermission role_id={role_id} />
          </Panel>
        </Collapse>
      </Form.Item>
      <Form.Item title="">
        <Button htmlType="submit" type="primary">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
