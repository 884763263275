import { gql } from "@apollo/client";

export const COUPONS = gql`
  query getCoupons {
    coupons {
      id
      key
      name
      type
      amount_type
      amount
      start_period
      end_period
      description
      enable
      is_expired
      created_at
      updated_at
    }
  }
`;
