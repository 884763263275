import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation($username: String!, $password: String!) {
    login(data: { username: $username, password: $password }) {
      access_token
      refresh_token
      expires_in
      token_type
    }
  }
`;
