import { useState, useEffect } from "react";
import { Alert, message, Form } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import CouponForm from "Pages/Coupons/CouponForm";
import { CREATE_COUPON } from "GraphQL/Mutations";
import { useMutation } from "@apollo/client";

const Add = () => {
  const [form] = Form.useForm();
  const [createCoupon, { data, error, loading }] = useMutation(CREATE_COUPON);

  const submit = (values) => {
    const { period, ...data } = values;
    const variables = {
      ...data,
      start_period: period[0].format("YYYY-MM-DD 00:00:00"),
      end_period: period[1].format("YYYY-MM-DD 23:59:59"),
    };
    createCoupon({ variables }).then(() => {
      message.success(`Coupon ${data.key} created`);
      form.resetFields();
    });
  };

  return (
    <>
      {error && (
        <Alert
          message={
            <div>
              {error.message}
              {error.graphQLErrors && (
                <ul>
                  {error.graphQLErrors.map((err, i) =>
                    Object.keys(err.extensions.validation).map((key) =>
                      err.extensions.validation[key].map((errMsg) => (
                        <li key={`${i}${key}${errMsg}`}>{errMsg}</li>
                      ))
                    )
                  )}
                </ul>
              )}
            </div>
          }
          type="error"
          showIcon
        />
      )}
      <PageHeader title="Add new coupon" />
      <CouponForm form={form} submit={submit} />
    </>
  );
};

export default Add;
