import React from "react";
import { Spin, Alert } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import CustomerForm from "./CustomerForm";
import { CUSTOMER } from "GraphQL/Queries";
import { UPDATE_CUSTOMER } from "GraphQL/Mutations";
import { useQuery, useMutation } from "@apollo/client";
const CustomerFormWrapper = CustomerForm;

const Edit = (props) => {
  const { id } = props.match.params;

  const {
    data: customer,
    loading: customerLoading,
    error: customerError,
  } = useQuery(CUSTOMER, { variables: { id } });

  const [updateCustomer, { data: updateSuccess, error: updateError }] =
    useMutation(UPDATE_CUSTOMER);

  const submit = (values) => {
    updateCustomer({
      variables: {
        id,
        name: values.name,
        phone_number: values.phone_number,
        phone_number_2: values.phone_number_2,
        address: values.address,
        province: values.locations[0],
        district: values.locations[1],
        sub_district: values.sub_district,
        postal_code: values.postal_code,
      },
    });
  };

  if (customerLoading) {
    return <Spin />;
  }

  return (
    <React.Fragment>
      {updateError && (
        <Alert message={updateError.message} type="error" showIcon />
      )}
      {updateSuccess && (
        <Alert message="Success update" type="success" showIcon />
      )}

      <PageHeader title="Edit customer" />

      <CustomerFormWrapper
        initialValues={customerLoading ? {} : customer.user}
        editMode={true}
        submit={submit}
      />
    </React.Fragment>
  );
};
export default Edit;
