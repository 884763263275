import React from "react";
import { Spin, Alert } from "antd";
import { UPDATE_PRODUCT } from "GraphQL/Mutations";
import { PRODUCT } from "GraphQL/Queries";
import ProductForm from "./ProductForm";
import { useQuery, useMutation } from "@apollo/client";
import { PageHeader } from "@ant-design/pro-components";
const ProductFormWrapped = ProductForm;

let formRef;
const setFormValue = (values) => {
  const { name } = values;
  formRef.setFieldsValue({ name });
};
const EditProduct = (props) => {
  const [
    editProduct,
    { data: updateSuccess, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_PRODUCT);
  const { id } = props.match.params;
  const { data, error, loading } = useQuery(PRODUCT, { variables: { id } });
  const submit = (values) => {
    console.log(values);
    values.category_id = Number(values.category_id);
    values.minqty = Number(values.minqty);
    values.weight = Number(values.weight);
    values.is_active = values.is_active ? 1 : 0;
    editProduct({ variables: { id, ...values } });
  };
  if (loading) {
    return <Spin spinning={true} />;
  }

  return (
    <Spin spinning={updateLoading}>
      {updateError && (
        <Alert message={updateError.message} type="error" showIcon />
      )}
      {updateSuccess && (
        <Alert message="Success update" type="success" showIcon />
      )}

      <PageHeader title="Edit product" />

      <ProductFormWrapped
        initialValues={loading ? {} : data.product}
        editMode={true}
        submit={submit}
      />
    </Spin>
  );
};

export default EditProduct;
