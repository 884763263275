import React from "react";
import { Table } from "antd";
const AgentByMonthTable = props => {
  const { data } = props;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Quantities Count",
      dataIndex: "quantity",
      key: "quantity"
    },
    {
      title: "Invoices Count",
      dataIndex: "transactions",
      key: "transactions"
    }
  ];

  return (
    <Table
      size="small"
      columns={columns}
      rowKey={record => record.id}
      dataSource={data}
    />
  );
};

export default AgentByMonthTable;
