import React from "react";
import { Tabs, Modal } from "antd";
import Select from "./Select";
import New from "./New";
import Edit from "./Edit";
const { TabPane } = Tabs;
const Main = props => {
  const { onCancel, submit } = props;
  return (
    <Modal {...props} footer={null}>
      <Tabs defaultActiveKey="tabCustomer">
        <TabPane tab="Customers" key="tabCustomer">
          <Select submit={submit} />
        </TabPane>
        <TabPane tab="New" key="tabNew">
          <New submit={submit} />
        </TabPane>
      </Tabs>
    </Modal>
  );
};
Main.Edit = Edit;
export default Main;
