import { gql } from '@apollo/client';

export const CREATE_BRANCH = gql`
  mutation($email: String, $name: String, $phone_number: String, $is_active: Boolean) {
    createBranch(email: $email, name: $name, phone_number: $phone_number, is_active: $is_active) {
      id
      email
    }
  }
`;

export const UPDATE_BRANCH = gql`
  mutation($id: ID!, $name: String, $phone_number: String, $email: String, $is_active: Boolean) {
    updateBranch(
      id: $id
      name: $name
      phone_number: $phone_number
      email: $email,
      is_active: $is_active
    ) {
      id
      email
      name
      phone_number
      is_active
    }
  }
`;
