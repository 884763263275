import React, { useState, useEffect } from "react";
import { Select } from "antd";
import Locations from "Helpers/Locations";
const LocationSelectMini = React.forwardRef((props, ref) => {
  const provinces = Locations.getLocations();
  const { onChange = () => {}, value, ...rest } = props;
  const [state, setState] = useState({
    districts: [],
    selectedProvince: value ? value[0] : undefined,
    selectedDistrict: value ? value[1] : undefined,
  });

  const { districts, selectedProvince, selectedDistrict } = state;

  useEffect(() => {
    console.log("value", value, selectedDistrict);
    if (value) {
      const provincesFiltered = provincesFilter(value[0]);
      setState({
        ...state,
        selectedProvince: value[0],
        selectedDistrict: value[1],
        districts:
          provincesFiltered.length > 0 ? provincesFiltered[0].kabupatens : [],
      });
    }
  }, []);

  const provincesFilter = (id) => {
    const provincesFiltered = provinces.filter(
      (province) => province.id === id
    );
    return provincesFiltered;
  };

  return (
    <div style={{ minWidth: "400px", display: "flex" }} ref={ref}>
      <Select
        {...rest}
        showSearch
        value={selectedProvince}
        optionFilterProp="children"
        allowClear
        placeholder="Select province"
        onChange={(value) => {
          const provincesFiltered = provincesFilter(value);
          setState({
            ...state,
            selectedProvince: value,
            selectedDistrict: undefined,
            districts:
              provincesFiltered.length > 0
                ? provincesFiltered[0].kabupatens
                : [],
          });
          onChange([value, undefined]);
        }}
      >
        {provinces.map((province) => (
          <Select.Option key={province.id} value={province.id}>
            {province.nama}
          </Select.Option>
        ))}
      </Select>
      &nbsp;/&nbsp;
      <Select
        allowClear
        showSearch
        optionFilterProp="children"
        value={selectedDistrict}
        placeholder="Select district"
        {...rest}
        onChange={(value) => {
          setState({
            ...state,
            selectedDistrict: value,
          });
          if (value) {
            onChange([selectedProvince, value]);
          }
        }}
      >
        {districts.map((district) => (
          <Select.Option key={district.id} value={district.id}>
            {district.nama}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
});

export default LocationSelectMini;
