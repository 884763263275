import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { TRANSACTION, TRANSACTION_LOG } from "GraphQL/Queries";
import { processCoupon } from "Utils/Coupon";
import {
  DONE_TRANSACTION,
  CANCEL_TRANSACTION,
  ADD_COMMISION_TO_TRANSACTION,
  CLOSE_TRANSACTION,
} from "GraphQL/Mutations";
import {
  Descriptions,
  Spin,
  Tag,
  Table,
  Button,
  Form,
  Modal,
  Input,
  InputNumber,
  message,
  Row,
  Col,
  Timeline,
  Typography,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import StatusSelect from "UI/Input/StatusSelect";
import CourierSelect from "UI/Input/CourierSelect";
import Locations from "Helpers/Locations";
import ButtonGroup from "antd/lib/button/button-group";
import { useSelector } from "react-redux";
import { PrinterOutlined } from "@ant-design/icons";
const { Text } = Typography;

const StatusModal = (props) => {
  const { visible, onCancel, submit, loading, initialValue } = props;
  const { status, shipping_cost, courier_id } = initialValue;
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    submit(values);
  };

  const handleModalSubmit = () => {
    form.submit();
  };

  return (
    <Modal
      open={visible}
      title={`Set status to ${status}`}
      okText="Set"
      onCancel={onCancel}
      onOk={handleModalSubmit}
      confirmLoading={loading}
    >
      <Form layout="inline" onFinish={handleSubmit} form={form}>
        <Form.Item
          label="Status"
          style={{ display: "none" }}
          name="status"
          initialValue={status}
        >
          <StatusSelect></StatusSelect>
        </Form.Item>

        {status === "CANCELLED" && (
          <Form.Item
            label="Reason"
            name="reason"
            rules={[
              {
                required: status === "CANCELLED",
              },
            ]}
          >
            <Input.TextArea></Input.TextArea>
          </Form.Item>
        )}
        {status === "DONE" && (
          <Form.Item
            label="Additional Fee"
            name="additional_fee"
            initialValue="0"
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) => window.idCurrency.formatSimple(value)}
              parser={(value) => window.idCurrency.parseSimple(value)}
            />
          </Form.Item>
        )}
        {status === "DONE" && (
          <Form.Item
            label="Shipping Cost"
            name="shipping_cost"
            initialValue={shipping_cost}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) => window.idCurrency.formatSimple(value)}
              parser={(value) => window.idCurrency.parseSimple(value)}
            />
          </Form.Item>
        )}
        {status === "DONE" && (
          <Form.Item
            label="Courier"
            name="courier_id"
            initialValue={courier_id}
          >
            <CourierSelect />
          </Form.Item>
        )}
        {status === "ADDED_COMMISION" && (
          <Form.Item label="Shipping Cost" name="commision" initialValue="0">
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) => window.idCurrency.formatSimple(value)}
              parser={(value) => window.idCurrency.parseSimple(value)}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

const View = (props) => {
  const me = useSelector((state) => state.me);
  const permissions = useSelector((state) => state.permissions);
  const [state, setState] = useState({
    statusModalVisible: false,
    statusModalLoading: false,
    initialModalStatus: "",
  });
  const { statusModalVisible, initialModalStatus, statusModalLoading } = state;
  const { id } = props.match.params;

  const {
    data,
    error,
    loading,
    refetch: refetchTransaction,
  } = useQuery(TRANSACTION, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: logData,
    loading: logLoading,
    error: logError,
    refetch: refetchLog,
  } = useQuery(TRANSACTION_LOG, {
    variables: { transaction_id: Number(id) },
    fetchPolicy: "cache-and-network",
  });

  const [
    doneTransaction,
    { data: doneData, loading: doneLoading, error: doneError },
  ] = useMutation(DONE_TRANSACTION);
  const [
    cancelTransaction,
    { data: cancelData, loading: cancelLoading, error: cancelError },
  ] = useMutation(CANCEL_TRANSACTION);
  const [
    closeTransaction,
    { data: closeData, loading: closeLoading, error: closeError },
  ] = useMutation(CLOSE_TRANSACTION);

  const [
    addCommisionToTransaction,
    { data: commisionData, loading: commisionLoading, error: errorCommision },
  ] = useMutation(ADD_COMMISION_TO_TRANSACTION);

  const columns = [
    {
      title: "Product name",
      dataIndex: "product.name",
      key: "product.name",
      render: (text, record) => <span>{record.product.name}</span>,
    },
    {
      title: "Qty",
      align: "right",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price",
      align: "right",
      dataIndex: "price",
      key: "price",
      render: (text, record) => (
        <span>@{window.idCurrency.formatSimple(record.price)}</span>
      ),
    },
    {
      title: "Subtotal",
      align: "right",
      dataIndex: "Subtotal",
      key: "subtotal",
      render: (text, record) => (
        <span>
          {window.idCurrency.formatSimple(record.quantity * record.price)}
        </span>
      ),
    },
  ];

  const closeStatusModal = (success = false) => {
    if (success) {
      refetchTransaction()
        .then(() => {
          return refetchLog();
        })
        .then(() => {
          setState({
            ...state,
            statusModalVisible: false,
          });
          message.success("Success updates status");
        });
    }
  };

  const handleSubmit = (values) => {
    setState({
      ...state,
      statusModalLoading: true,
    });
    const val = {
      ...values,
    };
    if (values.additional_fee) {
      val.additional_fee = Number(values.additional_fee);
    }

    if (values.shipping_cost) {
      val.shipping_cost = Number(values.shipping_cost);
    }

    if (values.courier_id) {
      val.courier_id = Number(values.courier_id);
    }

    if (values.commision) {
      val.commision = Number(values.commision);
    }

    switch (val.status) {
      case "DONE":
        doneTransaction({
          variables: {
            id,
            ...val,
          },
        }).then(() => closeStatusModal(true));
        break;
      case "CANCELLED":
        cancelTransaction({
          variables: {
            id,
            ...val,
          },
        }).then(() => closeStatusModal(true));
        break;
      case "ADDED_COMMISION":
        addCommisionToTransaction({
          variables: {
            id,
            ...val,
          },
        }).then(() => closeStatusModal(true));
        break;
      case "CLOSED": {
        closeTransaction({
          variables: {
            id,
            ...val,
          },
        }).then(() => closeStatusModal(true));
        break;
      }
      default:
        throw new Error("Unknown status:", val.status);
    }
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Transaction Detail"
        extra={[
          <Button
            key="print"
            icon={<PrinterOutlined />}
            type="primary"
            href={`/transaction/${id}/print`}
            target="_blank"
          >
            Print
          </Button>,
        ]}
      />
      <Spin spinning={loading}>
        {!loading &&
          !logLoading &&
          (() => {
            const { transaction } = data;
            return (
              <React.Fragment>
                {statusModalVisible && (
                  <StatusModal
                    visible={statusModalVisible}
                    loading={statusModalLoading}
                    onCancel={() => {
                      setState({
                        ...state,
                        statusModalVisible: false,
                      });
                    }}
                    submit={handleSubmit}
                    initialValue={{
                      status: initialModalStatus,
                      shipping_cost: transaction.shipping_cost,
                      additional_fee: transaction.additional_fee,
                      courier_id: transaction.courier_id + "",
                    }}
                  />
                )}
                <Row gutter={16}>
                  <Col span={12}>
                    <Descriptions title="Log">
                      <Descriptions.Item>
                        <Timeline>
                          {logData.transactionsLog.data.map((log) => (
                            <Timeline.Item
                              key={log.id}
                              color={
                                log.after_status === "CANCELLED"
                                  ? "red"
                                  : "blue"
                              }
                            >
                              Status set to {log.after_status} on{" "}
                              {new Date(
                                Date.parse(log.created_at)
                              ).toDateString()}
                              <br /> {log.agent.name}@{log.agent.branch.name}
                            </Timeline.Item>
                          ))}
                        </Timeline>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={12}>
                    <Descriptions size="small" bordered layout="vertical">
                      <Descriptions.Item label="Invoice">
                        {transaction.invoice_number}
                      </Descriptions.Item>
                      <Descriptions.Item label="Created by">
                        {transaction.agent.name}@{transaction.agent.branch.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Created at">
                        {new Date(
                          Date.parse(transaction.created_at)
                        ).toDateString()}
                      </Descriptions.Item>
                      <Descriptions.Item label="Assigned to">
                        {transaction.seller.name}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={(() => (
                          <span>
                            Status:&nbsp;
                            <Tag
                              color={
                                transaction.status === "PENDING"
                                  ? "grey"
                                  : transaction.status === "CANCELLED"
                                  ? "red"
                                  : "green"
                              }
                            >
                              {transaction.status}
                            </Tag>
                          </span>
                        ))()}
                        span={3}
                      >
                        <ButtonGroup>
                          <Button
                            type="default"
                            danger={true}
                            onClick={() => {
                              setState({
                                ...state,
                                statusModalVisible: true,
                                statusModalLoading: false,
                                initialModalStatus: "CANCELLED",
                              });
                            }}
                            disabled={transaction.status !== "PENDING"}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => {
                              setState({
                                ...state,
                                statusModalVisible: true,
                                statusModalLoading: false,
                                initialModalStatus: "DONE",
                              });
                            }}
                            disabled={
                              transaction.status !== "PENDING" ||
                              !permissions.CAN_DONE_TRANSACTION_SELL
                            }
                          >
                            Done
                          </Button>
                          <Button
                            type="default"
                            onClick={() => {
                              if (
                                permissions.CAN_ADD_COMMISION_TRANSACTION_SELL
                              ) {
                                setState({
                                  ...state,
                                  statusModalVisible: true,
                                  statusModalLoading: false,
                                  initialModalStatus: "ADDED_COMMISION",
                                });
                              } else {
                                alert(
                                  "Permission denied, only owner can set permission"
                                );
                              }
                            }}
                            disabled={
                              transaction.status !== "DONE" ||
                              !permissions.CAN_ADD_COMMISION_TRANSACTION_SELL ||
                              me.branch.id !== 1
                            }
                          >
                            Add Commision
                          </Button>
                          <Button
                            onClick={() => {
                              setState({
                                ...state,
                                statusModalVisible: true,
                                statusModalLoading: false,
                                initialModalStatus: "CLOSED",
                              });
                            }}
                            type="default"
                            disabled={
                              transaction.status === "CLOSED" ||
                              transaction.status !== "ADDED_COMMISION" ||
                              !permissions.CAN_CLOSE_TRANSACTION_SELL ||
                              me.branch.id !== 1
                            }
                          >
                            Close
                          </Button>
                        </ButtonGroup>
                      </Descriptions.Item>
                      {transaction.status === "CANCELLED" && (
                        <Descriptions.Item label="Cancel reason" span={3}>
                          {transaction.description}
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </Col>
                </Row>

                <br />
                <Descriptions size="small" bordered title="Customer">
                  <Descriptions.Item label="Name" span={2}>
                    {transaction.buyer.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Contact" span={2}>
                    {transaction.phone_number
                      ? transaction.phone_number
                      : `*${
                          transaction.buyer.phone_number
                            ? transaction.buyer.phone_number
                            : ""
                        }`}
                    {`${
                      transaction.buyer.phone_number_2
                        ? ` / ${transaction.buyer.phone_number_2}`
                        : ""
                    }`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address" span={3}>
                    address {transaction.sub_district},{" "}
                    {Locations.getCaption(transaction.district)},{" "}
                    {Locations.getCaption(transaction.province)}
                  </Descriptions.Item>
                </Descriptions>
                <br />
                <Table
                  rowKey={(record) => record.id}
                  dataSource={transaction.inventories}
                  columns={columns}
                  pagination={false}
                />
                <br />
                <Descriptions title="Summary" bordered>
                  <Descriptions.Item label="Courier">
                    {window.idCurrency.formatSimple(transaction.shipping_cost)}
                    &nbsp;@&nbsp;{transaction.courier.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Subtotal">
                    {processCoupon(transaction.coupon, transaction.price_total)
                      .cut > 0 && (
                      <>
                        <Text delete>
                          {window.idCurrency.format(transaction.price_total)}
                        </Text>{" "}
                        <Tag color="green">
                          -&nbsp;
                          {window.idCurrency.format(
                            processCoupon(
                              transaction.coupon,
                              transaction.price_total
                            ).cut
                          )}
                          &nbsp;off
                        </Tag>{" "}
                      </>
                    )}
                    {window.idCurrency.format(
                      processCoupon(transaction.coupon, transaction.price_total)
                        .price
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Coupon">
                    {transaction.coupon ? (
                      <div>
                        <div>
                          <strong>{transaction.coupon.key}</strong>&nbsp;-&nbsp;
                          {transaction.coupon.name}
                        </div>
                        <div>
                          {transaction.coupon.description}
                          {transaction.coupon.amount_type === "PERCENTAGE" && (
                            <Tag color="green">
                              -{transaction.coupon.amount}%&nbsp;off
                            </Tag>
                          )}
                          {transaction.amount_type === "FIXED" && (
                            <Tag color="green">
                              -
                              {window.idCurrency.format(
                                transaction.coupon.amount
                              )}
                              &nbsp;off
                            </Tag>
                          )}
                        </div>
                      </div>
                    ) : (
                      <em>No coupon applied</em>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Grandtotal">
                    {processCoupon(transaction.coupon, transaction.price_total)
                      .cut > 0 && (
                      <>
                        <Text delete>
                          {window.idCurrency.format(
                            transaction.price_total + transaction.shipping_cost
                          )}
                        </Text>{" "}
                        <Tag color="green">
                          -&nbsp;
                          {window.idCurrency.format(
                            processCoupon(
                              transaction.coupon,
                              transaction.price_total
                            ).cut
                          )}
                          &nbsp;off
                        </Tag>{" "}
                      </>
                    )}
                    {window.idCurrency.format(
                      processCoupon(transaction.coupon, transaction.price_total)
                        .price + transaction.shipping_cost
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="TV">
                    {transaction.tv.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Additional Fee">
                    {window.idCurrency.formatSimple(transaction.additional_fee)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Commision">
                    {window.idCurrency.formatSimple(transaction.commision)}
                  </Descriptions.Item>
                </Descriptions>
              </React.Fragment>
            );
          })()}
      </Spin>
    </React.Fragment>
  );
};

export default View;
