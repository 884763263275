import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, from}  from "@apollo/client";
// import { createHttpLink } from "apollo-link-http";
// import { setContext } from "apollo-link-context";
// import { InMemoryCache } from "apollo-cache-inmemory";
// import {  } from '@apollo/client';


// const httpLink = createHttpLink({
//   uri: process.env.REACT_APP_GRAPHQL_URL,
// });

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  loadDevMessages();
  loadErrorMessages();
}

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL + "/graphql" });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({headers = {}}) => {
    const token = localStorage.getItem("access_token");
    return {
      headers: {
        ...headers,
        // @ts-ignore
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
  return forward(operation);
});


const client = new ApolloClient({
  // @ts-ignore
  link: from([authMiddleware, httpLink]),
  cache: new InMemoryCache({
    dataIdFromObject: (o) => (o._id ? `${o.__typename}:${o._id}` : null),
  }),
});

export default client;
