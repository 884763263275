import React from "react";
import { Select, Spin } from "antd";
import { useQuery } from "@apollo/client";
import { CATEGORIES } from "GraphQL/Queries";

const { Option, OptGroup } = Select;

const CategorySelect = React.forwardRef((props, ref) => {
  const { hideall = false } = props;
  const { data, loading, error } = useQuery(CATEGORIES, {
    fetchPolicy: "cache-and-network",
    variables: { first: 1000, page: 1 },
  });
  const all = (
    <Option key={0} value={0}>
      All
    </Option>
  );
  console.log("props", props);
  return (
    <Select
      ref={ref}
      style={{ minWidth: 150 }}
      {...props}
      optionFilterProp="children"
      showSearch
    >
      {!loading &&
        [!hideall && all].concat(
          data.categories.data.map((category) => (
            <Option key={category.id} value={Number(category.id)}>
              {category.name}
            </Option>
          ))
        )}
    </Select>
  );
});

export default CategorySelect;
