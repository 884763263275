import { gql } from '@apollo/client';

export const CREATE_SUPPLIER = gql`
  mutation($email: String, $name: String, $phone_number: String) {
    createSupplier(email: $email, name: $name, phone_number: $phone_number) {
      id
      email
      name
    }
  }
`;

export const UPDATE_SUPPLIER = gql`
  mutation($id: ID!, $name: String!, $phone_number: String) {
    updateSupplier(id: $id, name: $name, phone_number: $phone_number) {
      id
      email
      name
      phone_number
      address
      province
      district
      sub_district
      postal_code
      created_at
      updated_at
    }
  }
`;
