import { gql } from '@apollo/client';

export const CATEGORIES = gql`
  query categories($first: Int!, $page: Int!) {
    categories(parent_category: 0, first: $first, page: $page) {
      paginatorInfo {
        lastPage
        total
      }
      data {
        id
        name
      }
    }
  }
`;

export const CATEGORY = gql`
  query($id: ID!) {
    categoryById(id: $id) {
      id
      parent_category
      name
    }
  }
`;
