import { gql } from '@apollo/client';

export const ME = gql`
  query {
    me {
      id
      email
      name
      role {
        id
        name
      }
      branch {
        id
        name
      }
      meta {
        id
        user_id
        key
        value
      }
    }
  }
`;
