import { gql } from "@apollo/client";

export const CUSTOMERS = gql`
  query customers(
    $first: Int!
    $page: Int!
    $keyword: String
    $province: String
    $district: String
    $sub_district: String
  ) {
    customers(
      first: $first
      page: $page
      keyword: $keyword
      province: $province
      district: $district
      sub_district: $sub_district
    ) {
      paginationInfo {
        total
        lastPage
      }
      data {
        id
        name
        email
        phone_number
        phone_number_2
        address
        province
        district
        sub_district
        postal_code
      }
    }
  }
`;

export const CUSTOMER = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      email
      is_active
      name
      phone_number
      phone_number_2
      address
      province
      district
      sub_district
      postal_code
    }
  }
`;
