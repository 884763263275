import React from "react";
import { useQuery } from "@apollo/client";
import { Select, Spin } from "antd";
import { TV } from "GraphQL/Queries";
const TvSelect = React.forwardRef((props, ref) => {
  const { hideall = true } = props;
  const { error, loading, data } = useQuery(TV, {
    fetchPolicy: "cache-and-network",
    variables: { first: 10000, page: 1 },
  });
  return (
    <Spin spinning={loading}>
      <Select
        {...props}
        placeholder="tv"
        style={{ minWidth: "150px" }}
        defaultValue={null}
        ref={ref}
      >
        {!hideall && (
          <Select.Option key="all" value="all">
            All
          </Select.Option>
        )}
        {!loading &&
          data.tvData.data
            .filter((tv) => tv.enable)
            .map((tv) => (
              <Select.Option key={tv.id} value={tv.id}>
                {tv.name}
              </Select.Option>
            ))}
      </Select>
    </Spin>
  );
});
export default TvSelect;
