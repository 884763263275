import React from "react";
import { Tabs } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import Courier from "./Courier";
import Tv from "./Tv";
import Supplier from "./Supplier";
import { useSelector } from "react-redux";
const { TabPane } = Tabs;
const Index = () => {
  const me = useSelector(state => state.me);
  const permissions = useSelector(state => state.permissions);
  return (
    <React.Fragment>
      <PageHeader title="Entities" />
      <Tabs>
        <TabPane tab="TV" key="tv">
          <Tv />
        </TabPane>
        <TabPane tab="Courier" key="courier">
          <Courier />
        </TabPane>
        {permissions.CAN_VIEW_SUPPLIER && me.branch.id === 1 && (
          <TabPane tab="Supplier" key="supplier">
            <Supplier />
          </TabPane>
        )}
      </Tabs>
    </React.Fragment>
  );
};

export default Index;
