import React from "react";
import { ME } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { Redirect, Route } from "react-router-dom";

const SecureRoute = props => {
  // const { data, loading, error } = useQuery(ME);

  // console.log("error");

  // if (error) {
  //   return <Redirect to="/login" />;
  // }
  // if (!loading && isNull(data.me)) {
  //   return <Redirect to="/login" />;
  // }
  // if (!localStorage.getItem("access_token")) {
  //   return <Redirect to="/login" />;
  // }
  return <Route {...props} />;
};

export default SecureRoute;
