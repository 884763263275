import Store from "Store/Global";

class Locations {
  static start() {
    Store.subscribe(() => (this.locations = Store.getState().locations));
  }
  static fetch() {
    if (this.locations) {
      return this.locations;
    }

    this.locations = Store.getState().locations;
    return this.locations;
  }
  static getCaption(id) {
    const locations = this.fetch();
    for (let i = 0; i < locations.length; i++) {
      if (locations[i].id === id) {
        return locations[i].nama;
      }
      for (let j = 0; j < locations[i].kabupatens.length; j++) {
        if (locations[i].kabupatens[j].id === id) {
          return locations[i].kabupatens[j].nama;
        }
        for (let k = 0; k < locations[i].kabupatens[j].kecamatans.length; k++) {
          if (locations[i].kabupatens[j].kecamatans[k].id === id) {
            return locations[i].kabupatens[j].kecamatans[k].nama;
          }
        }
      }
    }
    return "";
  }

  static getLocations() {
    return this.fetch();
  }
}
Locations.start();
export default Locations;
