import React from "react";
import { UPDATE_USER } from "GraphQL/Mutations";
import { USER } from "GraphQL/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { Spin, message } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import UserForm from "./UserForm";
const Edit = props => {
  const { id } = props.match.params;
  const { data, loading, error } = useQuery(USER, { variables: { id } });
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER);
  if (loading) {
    return <Spin spinning={loading} />;
  }
  const handleSubmit = v => {
    v.id = id;
    updateUser({ variables: v })
      .then(() => {
        message.success("User updated");
      })
      .catch(err => message.error(err.message));
  };

  const initialValues = {...data.user}

  data.user.meta.forEach(meta => {
    if(meta.key === "tv_id"){
      initialValues.tv_id = meta.value
    }
  })

  return (
    <React.Fragment>
      <PageHeader title="Update User" />
      <Spin spinning={updateLoading}>
        <UserForm editMode initialValues={initialValues} submit={handleSubmit} />
      </Spin>
    </React.Fragment>
  );
};

export default Edit;
