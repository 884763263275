import React from "react";
import { TRANSACTION } from "GraphQL/Queries";
import { Spin, Descriptions, Table, Button } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useQuery } from "@apollo/client";
import { PrinterOutlined } from "@ant-design/icons";
const View = props => {
  const { id } = props.match.params;
  const { data, loading, error } = useQuery(TRANSACTION, { variables: { id } });
  if (loading) {
    return <Spin spinning={loading} />;
  }

  const transaction = data.transaction;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => <span>{record.product.name}</span>
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right"
    }
  ];
  return (
    <React.Fragment>
      <PageHeader
        title="Migration Detail"
        extra={[
          <Button
            key="print"
            target="_blank"
            type="primary"
            icon={<PrinterOutlined />}
            href={`/stocks/${id}/print`}
          >
            Print
          </Button>
        ]}
      />
      <Descriptions>
        <Descriptions.Item label="INV">
          {transaction.invoice_number}
        </Descriptions.Item>
        <Descriptions.Item label="Destination">
          {transaction.buyer.name}
        </Descriptions.Item>
      </Descriptions>
      <br />

      <Table
        dataSource={transaction.inventories}
        pagination={false}
        columns={columns}
      />
    </React.Fragment>
  );
};

export default View;
