import React from "react";
import { DatePicker, Form, Descriptions, Button, Spin, Typography } from "antd";
import qs from "qs";
import { useSelector } from "react-redux";
import BranchSelect from "UI/Input/BranchSelect";
import { useLazyQuery } from "@apollo/client";
import { REPORT_BY_AGENT } from "GraphQL/Queries";
import AgentByMonthTable from "./AgentByMonthTable";
import { DownloadOutlined } from "@ant-design/icons";
const { MonthPicker } = DatePicker;
const { Text } = Typography;

const FilterForm = props => {
  const { submit } = props;
  const [ form ] = Form.useForm();
  const me = useSelector(state => state.me);
  const handleSubmit = values => {
    submit(values);
  };

  const handleDownloadButton = () => {
    form.setFieldValue('download', true);
    form.submit();
  }
  return (
    <React.Fragment>
      <Form onFinish={handleSubmit} form={form}>
        <Descriptions bordered title="Filter">
          <Descriptions.Item label="Month" span={3}>
            <Form.Item label="Start" name="start_month"  rules={[{ required: true, message: "Date range required" }]}>
              <MonthPicker />
            </Form.Item>
            <Form.Item label="End" name="end_month" rules={[{ required: true, message: "Date range required" }]}>
              <MonthPicker />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Branch">
            <Form.Item name="branch_id" initialValue={me.branch.id + ""}>
               <BranchSelect hideall />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item>
            <Button htmlType="submit" type="primary">
              Generate
            </Button>
            <Button
              type="link"
              icon={<DownloadOutlined />}
              onClick={handleDownloadButton}
            >
              Download
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </Form>
    </React.Fragment>
  );
};

const ByAgent = () => {
  const [getReport, { data, loading, error }] = useLazyQuery(REPORT_BY_AGENT, {
    fetchPolicy: "cache-and-network"
  });
  const handleSubmit = v => {
    const { download, start_month, end_month, ...rest } = v;
    let start_y = start_month.format("YYYY");
    let start_m = start_month.format("MM");
    let end_y = end_month.format("YYYY");
    let end_m = end_month.format("MM");
    let data = {
      start_year: start_y,
      start_month: start_m,
      end_year: end_y,
      end_month: end_m,
      ...rest
    };
    if (download) {
      if (download) {
        window.location.href = `${process.env.REACT_APP_SERVER_API_URL}/server/reports3?` + qs.stringify(data);
      }
    } else {
      getReport({
        variables: data
      });
    }
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <FilterForm submit={handleSubmit} />
        <br />
        {!loading &&
          data &&
          data.agentTransaction.data.map(rec => (
            <React.Fragment key={rec.created_at}>
              <Text>{rec.created_at}</Text>
              <AgentByMonthTable data={rec.agents} />
              <br />
            </React.Fragment>
          ))}
      </Spin>
    </React.Fragment>
  );
};
export default ByAgent;
