import React from "react";
import { Select } from "antd";
const StatusSelect = React.forwardRef((props, ref) => {
  const { hideall = false, defaultValue, ...rest } = props;
  return (
    <Select
      ref={ref}
      {...rest}
      style={{ minWidth: 150 }}
      defaultValue={defaultValue ? "" + defaultValue : "ALL"}
    >
      {!hideall && <Select.Option value="ALL">ALL</Select.Option>}
      <Select.Option value="PENDING">PENDING</Select.Option>
      <Select.Option value="DONE">DONE</Select.Option>
      <Select.Option value="CANCELLED">CANCELLED</Select.Option>
      <Select.Option value="ADDED_COMMISION">ADDED_COMMISION</Select.Option>
      <Select.Option value="CLOSED">CLOSED</Select.Option>
    </Select>
  );
});

export default StatusSelect;
