import React from "react";
import { Select, Spin } from "antd";
import { useQuery } from "@apollo/client";
import { COURIERS } from "GraphQL/Queries";
const CourierSelect = React.forwardRef((props, ref) => {
  const { hideFree = false } = props;
  const { data, error, loading } = useQuery(COURIERS, {
    fetchPolicy: "cache-and-network",
  });
  console.log("props", props.value);
  if (hideFree) {
    return (
      <Spin spinning={loading}>
        {!loading && (
          <Select {...props} style={{ minWidth: "150px" }} ref={ref}>
            {data.couriers
              .filter((courier) => courier.id !== 1 || courier.enable)
              .map((courier) => (
                <Select.Option key={courier.id} value={courier.id + ""}>
                  {courier.name}
                </Select.Option>
              ))}
          </Select>
        )}
      </Spin>
    );
  }
  return (
    <Spin spinning={loading}>
      {!loading && (
        <Select {...props} style={{ minWidth: "150px" }} ref={ref}>
          {data.couriers
            .filter((courier) => courier.enable)
            .map((courier) => (
              <Select.Option key={courier.id} value={courier.id + ""}>
                {courier.name}
              </Select.Option>
            ))}
        </Select>
      )}
    </Spin>
  );
});

export default CourierSelect;
