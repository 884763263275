import React from "react";
import { Form, Input, Button } from "antd";
import LocationSelectMini from "UI/Input/LocationSelectMini";

const CustomerForm = (props) => {
  const { submit, initialValues = {}, editMode = false } = props;
  const {
    email,
    name,
    phone_number,
    phone_number_2,
    address,
    province,
    district,
    sub_district,
    postal_code,
  } = initialValues;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 3,
      },
    },
  };

  const handleSubmit = (values) => {
    submit(values);
  };

  return (
    <Form layout="horizontal" {...formItemLayout} onFinish={handleSubmit}>
      <Form.Item
        label="Name"
        name="name"
        initialValue={name}
        rules={[{ required: true, message: "Username is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        initialValue={email ? "" + email : ""}
      >
        <Input disabled={editMode} type="email"></Input>
      </Form.Item>

      <Form.Item
        label="Phone"
        name="phone_number"
        initialValue={phone_number ? "" + phone_number : ""}
        rules={[{ required: true, message: "Phone number is required" }]}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="Phone 2"
        name="phone_number_2"
        initialValue={phone_number_2 ? "" + phone_number_2 : ""}
      >
        <Input></Input>
      </Form.Item>

      <Form.Item
        label="locations"
        name="locations"
        initialValue={
          province
            ? [province ? province : undefined, district ? district : undefined]
            : []
        }
        rules={[
          {
            type: "array",
            required: true,
            message: "Please select your locations!",
          },
          {
            validator: (rule, value, cb) => {
              if (value[0] === undefined || value[1] === undefined) {
                cb("Please select your locations!");
              } else {
                cb();
              }
            },
          },
        ]}
      >
        <LocationSelectMini />
      </Form.Item>
      <Form.Item
        label="Sub district"
        name="sub_district"
        initialValue={sub_district ? sub_district : ""}
      >
        <Input placeholder="Eg. kecamatan, kelurahan etc" />
      </Form.Item>
      <Form.Item
        label="Address"
        name="address"
        initialValue={address ? "" + address : ""}
      >
        <Input.TextArea></Input.TextArea>
      </Form.Item>
      <Form.Item
        label="Postal code"
        name="postal_code"
        initialValue={postal_code ? "" + postal_code : ""}
      >
        <Input type="number"></Input>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CustomerForm;
