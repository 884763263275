import {
  Table,
  Button,
  Modal,
  Form,
  Checkbox,
  message,
  Spin,
  Typography,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { FileAddOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { COUPONS } from "GraphQL/Queries";
import { UPDATE_COUPON } from "GraphQL/Mutations";
import { useEffect, useState } from "react";
import { formatDate } from "Utils/Date";

const { Text } = Typography;
const UpdateModal = ({ data, open, submit, onCancel, loading }) => {
  const [form] = Form.useForm();
  const handleModalSubmit = () => {
    form.submit();
  };
  return (
    <Modal
      open={open}
      onOk={handleModalSubmit}
      onCancel={onCancel}
      title={`Edit ${data.key} coupon`}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={submit}>
        <Form.Item
          label="Enable"
          name="enable"
          valuePropName="checked"
          initialValue={data.enable}
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const List = () => {
  const { data, loading, error, refetch } = useQuery(COUPONS);

  const [
    updateCoupon,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_COUPON, {
    refetchQueries: [
      {
        query: COUPONS,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const handleEditButton = (values) => {
    setModalVisible(true);
    setSelectedCoupon({ ...values });
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const submit = (values) => {
    updateCoupon({
      variables: {
        id: Number(selectedCoupon.id),
        enable: values.enable,
      },
    }).then(() => {
      message.success(`Coupon ${selectedCoupon.key} updated`);
      setModalVisible(false);
      // refetch();
    });
  };

  const columns = [
    {
      key: "id",
      title: "id",
      dataIndex: "id",
    },
    {
      key: "name",
      title: "Name",
      render: (_, values) => (
        <div>
          <div>
            <Text disabled={!values.enable}>
              {values.name} ({values.key}) {values.enable ? "" : " - Disabled"}
            </Text>
          </div>
          <div>
            <Text disabled={!values.enable}>
              Discount:{" "}
              {values.amount_type === "PERCENTAGE"
                ? `IDR ${values.amount}%`
                : values.amount}
            </Text>
          </div>
        </div>
      ),
    },
    {
      key: "period",
      title: "Period",
      render: (_, values) => (
        <div>
          {formatDate(values.start_period)} to {formatDate(values.end_period)}
        </div>
      ),
    },
    {
      key: "actions",
      title: "Actions",
      render: (_, values) => (
        <div>
          <Button
            disabled={updateLoading}
            type="link"
            onClick={() => handleEditButton(values)}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      {loading && <Spin spinning={true} />}
      {modalVisible && (
        <UpdateModal
          open={modalVisible}
          data={selectedCoupon}
          onCancel={handleModalClose}
          submit={submit}
          loading={updateLoading}
        />
      )}
      <PageHeader
        title="Coupons"
        extra={[
          <Link key="add" to="/coupons/add">
            <Button type="primary" icon={<FileAddOutlined />}>
              Add
            </Button>
          </Link>,
        ]}
      />
      <Table
        pagination={false}
        columns={columns}
        loading={loading}
        dataSource={data ? data.coupons : []}
      />
    </>
  );
};

export default List;
