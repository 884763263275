import { gql } from '@apollo/client';

export const ROLES = gql`
  query($first: Int!, $page: Int!) {
    roles(first: $first, page: $page) {
      paginatorInfo {
        count
      }
      data {
        id
        name
        is_default
      }
    }
  }
`;

export const ROLE_PERMISSION = gql`
  query($role_id: Int!) {
    RolePermission(role_id: $role_id) {
      id
      name
      permissions {
        id
        name
        permitted
      }
    }
  }
`;
