import React, { useState } from "react";
import {
  Spin,
  List,
  Button,
  Modal,
  Form,
  Input,
  message
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { CATEGORIES } from "GraphQL/Queries";
import { UPDATE_CATEGORY, CREATE_CATEGORY } from "GraphQL/Mutations";
import { useQuery, useMutation } from "@apollo/client";
import { FileAddOutlined } from "@ant-design/icons";

const CategoryModal = props => {
  const [form] = Form.useForm();
  const {
    loading,
    initialValues,
    onCancel,
    submit,
    title,
    okText,
    visible
  } = props;
  const { name } = initialValues;
  const handleSubmit = values => {
      submit(values);
  };
  const handleModalSubmit = e => {
    form.submit()
  }
  return (
    <Modal
      confirmLoading={loading}
      title={title}
      okText={okText}
      onCancel={onCancel}
      onOk={handleModalSubmit}
      open={visible}
    >
      <Form layout="inline" onFinish={handleSubmit} form={form}>
        <Form.Item label="name" rules={[{ required: true, message: "Category name is required" }]} initialValue={name}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Categories = () => {
  const [state, setState] = useState({
    mode: null,
    editId: null,
    categoryFormVisible: false,
    categoryFormLoading: false,
    categoryFormTitle: null,
    categoryFormOkText: null,
    categoryFormInitialValues: {}
  });

  const {
    mode,
    editId,
    categoryFormVisible,
    categoryFormTitle,
    categoryFormOkText,
    categoryFormLoading,
    categoryFormInitialValues
  } = state;
  const { data, loading, error, refetch } = useQuery(CATEGORIES, {
    variables: { page: 1, count: 9999999 },
    fetchPolicy: "cache-and-network"
  });

  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const [createCategory] = useMutation(CREATE_CATEGORY);

  if (loading) {
    return <Spin spinning={loading} />;
  }

  const { data: categories } = data.categories;

  const handleSubmit = v => {
    setState({
      ...state,
      categoryFormLoading: true
    });
    if (mode === "edit") {
      updateCategory({
        variables: {
          id: editId,
          parent_category: 0,
          name: v.name
        }
      }).then(() => {
        setState({
          ...state,
          categoryFormVisible: false
        });
        message.success("Category updated");
      });
    }
    if (mode === "new") {
      createCategory({
        variables: {
          name: v.name,
          parent_category: 0
        }
      })
        .then(() => {
          return refetch();
        })
        .then(() => {
          setState({
            ...state,
            categoryFormVisible: false
          });
          message.success("Category created");
        });
    }
  };
  const cancelModal = () => {
    setState({
      ...state,
      categoryFormVisible: false
    });
  };
  return (
    <React.Fragment>
      <PageHeader
        title="Categories"
        extra={[
          <Button
            key="createCategory"
            type="primary"
            icon={<FileAddOutlined />}
            onClick={() => {
              setState({
                ...state,
                categoryFormVisible: true,
                categoryFormLoading: false,
                categoryFormOkText: "Save",
                categoryFormTitle: "New category",
                mode: "new",
                categoryFormInitialValues: { name: "" }
              });
            }}
          >
            Add
          </Button>
        ]}
      />
      <br />
      {categoryFormVisible && (
        <CategoryModal
          okText={categoryFormOkText}
          title={categoryFormTitle}
          visible={categoryFormVisible}
          loading={categoryFormLoading}
          submit={handleSubmit}
          onCancel={cancelModal}
          initialValues={categoryFormInitialValues}
        />
      )}
      <List
        bordered
        dataSource={categories}
        renderItem={category => (
          <List.Item
            key={category.id}
            actions={[
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setState({
                    ...state,
                    categoryFormOkText: "Update",
                    categoryFormVisible: true,
                    categoryFormTitle: "Update category",
                    mode: "edit",
                    categoryFormLoading: false,
                    editId: category.id,
                    categoryFormInitialValues: { name: category.name }
                  });
                }}
              >
                Edit
              </Button>
            ]}
          >
            {category.name}
          </List.Item>
        )}
      />
    </React.Fragment>
  );
};

export default Categories;
