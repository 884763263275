import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
  mutation (
    $email: String
    $name: String
    $phone_number: String
    $phone_number_2: String
    $address: String
    $province: String
    $district: String
    $sub_district: String
    $postal_code: String
  ) {
    createCustomer(
      email: $email
      name: $name
      phone_number: $phone_number
      phone_number_2: $phone_number_2
      address: $address
      province: $province
      district: $district
      sub_district: $sub_district
      postal_code: $postal_code
    ) {
      id
      email
      is_active
      is_branchable
      name
      phone_number
      phone_number_2
      address
      province
      district
      sub_district
      postal_code
      created_at
      updated_at
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation (
    $id: ID!
    $name: String
    $phone_number: String
    $phone_number_2: String
    $address: String
    $province: String
    $district: String
    $sub_district: String
    $postal_code: String
  ) {
    updateCustomer(
      id: $id
      name: $name
      phone_number: $phone_number
      phone_number_2: $phone_number_2
      address: $address
      province: $province
      district: $district
      sub_district: $sub_district
      postal_code: $postal_code
    ) {
      id
      name
      email
      phone_number
      phone_number_2
      address
      province
      district
      sub_district
      postal_code
    }
  }
`;
