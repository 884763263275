import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
const Add = (props) => {
  const { submit, initialValues, form } = props;
  const { name, phone_number, email, is_active } = initialValues;
  const handleSubmit = (values) => {
    submit(values);
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  return (
    <Form onFinish={handleSubmit} {...formItemLayout}>
      <Form.Item
        label="Name"
        name="name"
        initialValue={name ? name : ""}
        rules={[{ required: true, message: "Name is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone_number"
        initialValue={phone_number ? phone_number : ""}
        rules={[{ required: true, message: "Phone number required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        initialValue={email ? email : ""}
        rules={[
          {
            required: true,
            message: "Email required for receiving notifications",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          xs: {
            offset: 0,
          },
          sm: {
            offset: 4,
          },
        }}
        name="is_active"
        valuePropName="checked"
        initialValue={!!is_active}
      >
        <Checkbox>Active</Checkbox>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Add;
