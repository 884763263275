import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation (
    $branch_id: Int
    $role_id: Int
    $email: String
    $password: String
    $name: String
    $phone_number: String
    $phone_number_2: String
    $tv_id: Int
  ) {
    createUser(
      role_id: $role_id
      email: $email
      password: $password
      name: $name
      phone_number: $phone_number
      phone_number_2: $phone_number_2
      branch_id: $branch_id
      tv_id: $tv_id
    ) {
      id
      email
    }
  }
`;

export const UPDATE_USER = gql`
  mutation (
    $id: ID!
    $role_id: Int
    $branch_id: Int
    $password: String
    $name: String
    $phone_number: String
    $phone_number_2: String
    $is_active: Boolean
    $tv_id: Int
  ) {
    updateUser(
      id: $id
      role_id: $role_id
      branch_id: $branch_id
      password: $password
      name: $name
      phone_number: $phone_number
      phone_number_2: $phone_number_2
      is_active: $is_active
      tv_id: $tv_id
    ) {
      id
      email
    }
  }
`;
