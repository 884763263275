import { gql } from '@apollo/client';

export const SUPPLIERS = gql`
  query($count: Int!, $page: Int!, $keyword: String) {
    suppliers(count: $count, page: $page, keyword: $keyword) {
      paginationInfo {
        total
        lastPage
      }
      data {
        id
        email
        name
        phone_number
      }
    }
  }
`;

export const SUPPLIER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      email
      is_active
      name
      phone_number
    }
  }
`;
