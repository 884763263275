import React from "react";
import { Tabs } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import ByProduct from "./ByProduct";

const { TabPane } = Tabs;
const List = () => {
  return (
    <React.Fragment>
      <PageHeader title="Reports" />
      <Tabs>
        <TabPane tab="By Product" key="byproduct">
          <ByProduct />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

export default List;
