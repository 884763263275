import React from "react";
import { TRANSACTION } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { Spin, Col, Row } from "antd";
import Locations from "Helpers/Locations";
import Table from "easy-table";
import "./page.css";

function padRight(val, width) {
  var str = String(val);
  var len = str.length;
  var pad = width > len ? Array(width - len + 1).join(" ") : "";
  return str + pad;
}
let print = false;
let additionalFee = false;
const Print = props => {
  document.body.classList.add("A4");
  const { id } = props.match.params;
  const { data, error, loading } = useQuery(TRANSACTION, { variables: { id } });

  if (loading) {
    return <Spin spinning={loading} />;
  }

  if (!print) {
    setTimeout(() => {
      window.print();
    }, 500);
    print = true;
  }

  const { transaction } = data;

  if (!additionalFee) {
    transaction.inventories.push({
      product: {
        id: "",
        name: "Additional fee"
      },
      quantity: 1,
      price: transaction.additional_fee
    });
    additionalFee = true;
  }

  const t = new Table();
  transaction.inventories.forEach(function(inventory) {
    t.cell("ID", Table.padLeft(inventory.product.id, 5));
    t.cell("Product", padRight(inventory.product.name, 30));
    t.cell("Qty", Table.padLeft(inventory.quantity, 5));
    t.cell("-", Table.padLeft(``, 16));
    t.cell("~", Table.padLeft(``, 16));
    t.newRow();
  });

  return (
    <section className="sheet padding-5mm">
      <Row>
        <Col span={12}>
          <pre>iBerkah</pre>
          <pre>Ruko Tekno Boulevard</pre>
          <pre>Jln Raya Tekno Widya, Tangerang-Sel</pre>
          <pre>Phone: (021) 75685390, Fax: 1012091</pre>
          <pre>INVOICE: {transaction.invoice_number}</pre>
          <pre> </pre>
        </Col>
        <Col span={12}>
          <pre>To:</pre>
          <pre>{transaction.buyer.name}</pre>
          <pre>Phone: {transaction.phone_number}</pre>
        </Col>

        <Col span={24}>
          <pre>{t.toString()}</pre>
        </Col>
        {Array(8 - transaction.inventories.length)
          .fill("")
          .map(() => (
            <Col key={Math.random()} span={24}>
              <pre> </pre>
            </Col>
          ))}
        <Col span={24}>
          <pre>{"-".repeat(80)}</pre>
          <pre>
            {Table.padLeft(
              `Shipping Cost: ${window.idCurrency.formatSimple(
                transaction.shipping_cost
              )} (${transaction.courier.name})`,
              80
            )}
          </pre>
          <pre> </pre>
        </Col>
        <Col span={12}>
          <pre>ttd, </pre>
          <pre> </pre>
          <pre> </pre>
          <pre>PT. Berkah</pre>
        </Col>
        <Col span={12}>
          <pre>Customer, </pre>
          <pre> </pre>
          <pre> </pre>
          <pre>_______________</pre>
        </Col>
      </Row>
    </section>
  );
};

export default Print;
