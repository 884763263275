import React from "react";
import { Form, Descriptions, Button, Spin, DatePicker, Input } from "antd";
import BranchSelect from "UI/Input/BranchSelect";
import TvSelect from "UI/Input/TvSelect";
import StatusSelect from "UI/Input/StatusSelect";
import { REPORT_BY_INVOICE } from "GraphQL/Queries";
import { useLazyQuery } from "@apollo/client";
import InvoiceDayTable from "./InvoiceDayTable";
import qs from "qs";
import { DownloadOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

const FilterForm = (props) => {
  const { submit } = props;
  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    submit(values);
  };
  const handleDownloadButton = () => {
    form.setFieldValue("download", true);
  };
  const handleSubmitButton = () => {
    form.setFieldValue("download", false);
  };
  return (
    <React.Fragment>
      <Form onFinish={handleSubmit} form={form}>
        <Descriptions bordered title="Filter">
          <Descriptions.Item label="Date" span={3}>
            <Form.Item
              name="dateRange"
              rules={[{ required: true, message: "Date range required" }]}
            >
              <RangePicker />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Branch">
            <Form.Item name="branch_id" initialValue="all">
              <BranchSelect />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="TV">
            <Form.Item name="tv_id" initialValue="all">
              <TvSelect hideall={false} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Inv Status">
            <Form.Item name="status" initialValue="ALL">
              <StatusSelect />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item>
            <Button
              htmlType="submit"
              type="primary"
              onClick={handleSubmitButton}
            >
              Generate
            </Button>
            <Button
              htmlType="submit"
              type="link"
              icon={<DownloadOutlined />}
              onClick={handleDownloadButton}
            >
              Download
            </Button>
          </Descriptions.Item>
        </Descriptions>
        <Form.Item hidden={true} name="download">
          <Input />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

const ByInvoice = () => {
  const [getReport, { data, loading, error }] = useLazyQuery(
    REPORT_BY_INVOICE,
    {
      fetchPolicy: "network-only",
    }
  );
  const handleSubmit = (values) => {
    const { dateRange, download, ...paramsRest } = values;
    if (paramsRest.branch_id === "all") {
      paramsRest.branch_id = null;
    } else {
      paramsRest.branch_id = Number(null);
    }
    if (paramsRest.tv_id === "all") {
      paramsRest.tv_id = null;
    } else {
      paramsRest.tv_id = Number(paramsRest.tv_id);
    }
    if (paramsRest.status === "ALL") {
      paramsRest.status = null;
    }
    paramsRest.date_from = dateRange[0].format("YYYY-MM-DD");
    paramsRest.date_to = dateRange[1].format("YYYY-MM-DD");
    if (download) {
      window.location.href =
        `${process.env.REACT_APP_SERVER_API_URL}/server/reports2?` +
        qs.stringify(paramsRest);
    } else {
      getReport({ variables: paramsRest });
    }
  };
  if (!loading) {
    console.log("aaa", data);
  }
  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <FilterForm submit={handleSubmit} />
        {!loading &&
          data &&
          data.invoiceTransaction.data.map((d) => (
            <React.Fragment key={d.created_at}>
              <Descriptions bordered layout="vertical">
                <Descriptions.Item label={d.created_at} span={3}>
                  <InvoiceDayTable data={d.transactions} />
                  <br />
                </Descriptions.Item>
              </Descriptions>
              <br />
            </React.Fragment>
          ))}
      </Spin>
    </React.Fragment>
  );
};

export default ByInvoice;
