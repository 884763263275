import { gql } from '@apollo/client';
export const TV = gql`
  query tv($first: Int!, $page: Int!) {
    tvData(first: $first, page: $page) {
      paginatorInfo {
        currentPage
        total
        count
      }
      data {
        id
        name
        enable
      }
    }
  }
`;
