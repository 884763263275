import React from "react";
import { Table, Typography, Tag } from "antd";
const { Text } = Typography;

const InvoiceDayTable = (props) => {
  const { data = [] } = props;
  console.log("daata", data);
  const columns = [
    {
      title: "INV",
      dataIndex: "invoice_number",
      key: "invoicenumber",
      render: (text, record) => <span>{record.invoice_number}</span>,
    },
    {
      title: "TV",
      dataIndex: "tv",
      key: "tvstation",
      render: (text, record) => <span>{record.tv.name}</span>,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (text, record) => <span>{record.seller.name}</span>,
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      align: "right",
      render: (text, record) => {
        let count = 0;
        record.inventories.forEach((inventory) => {
          count += inventory.quantity;
        });
        return <span>{count}</span>;
      },
    },
    {
      title: "Coupon",
      key: "coupon",
      align: "right",
      render: (_, record) => {
        if (record.coupon) {
          return (
            <div>
              {record.coupon.key}{" "}
              {record.coupon.amount_type === "PERCENTAGE" && (
                <Tag color="green">-{record.coupon.amount}%&nbsp;off</Tag>
              )}
              {record.coupon.amount_type === "FIXED" && (
                <Tag color="green">
                  -{window.idCurrency.formatSimple(record.coupon.amount)}
                  &nbsp;off
                </Tag>
              )}
            </div>
          );
        }
        return <em>No coupon</em>;
      },
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "total",
      align: "right",
      render: (text, record) => (
        <span>{window.idCurrency.formatSimple(record.grandtotal)}</span>
      ),
    },
  ];
  return (
    <React.Fragment>
      <br />
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        pagination={false}
        footer={(record) => {
          let qtyTotal = 0;
          let grandtotal = 0;
          for (let i = 0; i < record.length; i++) {
            for (let j = 0; j < record[i].inventories.length; j++) {
              qtyTotal += record[i].inventories[j].quantity;
              grandtotal +=
                record[i].inventories[j].quantity *
                record[i].inventories[j].price;
            }
          }
          return (
            <div style={{ textAlign: "right" }}>
              Total Qty: {qtyTotal}, grandtotal:{" "}
              {window.idCurrency.formatSimple(grandtotal)}
            </div>
          );
        }}
      />
    </React.Fragment>
  );
};

export default InvoiceDayTable;
