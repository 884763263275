import React from "react";
import { ROLES } from "GraphQL/Queries";
import { Spin, Select } from "antd";
import { useQuery } from "@apollo/client";
const RoleSelect = React.forwardRef((props, ref) => {
  const { data, loading, error } = useQuery(ROLES, {
    fetchPolicy: 'cache-and-network',
    variables: { page: 1, first: 999999 }
  });

  return (
    <Select loading={loading} ref={ref} {...props}>
      {!loading &&
        data.roles.data
          .filter(r => !r.is_default)
          .map(role => (
            <Select.Option key={role.id} value={role.id}>
              {role.name}
            </Select.Option>
          ))}
    </Select>
  );
});
export default RoleSelect;
