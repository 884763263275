import React from "react";
import CustomerForm from "./CustomerForm";
import { Alert, Form } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { CREATE_CUSTOMER } from "GraphQL/Mutations";
import { useMutation } from "@apollo/client";

const Add = () => {
  const CustomerFormWrapper = CustomerForm;
  const [createCustomer, { data, loading, error }] =
    useMutation(CREATE_CUSTOMER);
  const submit = (values) => {
    createCustomer({
      variables: {
        email: values.email,
        name: values.name,
        phone_number: values.phone_number,
        phone_number_2: values.phone_number_2,
        address: values.address,
        province: values.locations[0],
        district: values.locations[1],
        sub_district: values.sub_district,
        postal_code: values.postal_code,
      },
    });
  };
  return (
    <React.Fragment>
      {error && <Alert message={error.message} type="error" showIcon />}
      {data && <Alert message="Success created" type="success" showIcon />}
      <PageHeader title="Add new customer" />
      <CustomerFormWrapper submit={submit} />
    </React.Fragment>
  );
};

export default Add;
