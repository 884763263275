import React, { useState } from "react";
import { MIGRATE_STOCK } from "GraphQL/Mutations";
import { Spin, message } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import StockMigrationForm from "./StockMigrationForm";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";
const Migrate = () => {
  const [redirect, setRedirect] = useState(false);
  const [migrateStock, { loading }] = useMutation(MIGRATE_STOCK);
  const handleSubmit = values => {
    migrateStock({ variables: { migrate_stock: values } })
      .then(() => {
        message.success("Stock migrated");
        setRedirect(true);
      })
      .catch(err => {
        message.error(err.message);
      });
  };
  if (redirect) {
    return <Redirect to={`/stocks`} />;
  }
  return (
    <React.Fragment>
      <PageHeader title="Migrate stock" />
      <br />
      <Spin spinning={loading}>
        <StockMigrationForm submit={handleSubmit} />
      </Spin>
    </React.Fragment>
  );
};

export default Migrate;
