import React from "react";
import { Form, Input, Button, Spin, Alert } from "antd";
import LocationSelectMini from "UI/Input/LocationSelectMini";
import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMER } from "GraphQL/Mutations";
const New = (props) => {
  const { submit, initialValues = {}, editMode = false } = props;
  const {
    email,
    name,
    phone_number,
    phone_number_2,
    address,
    province,
    district,
    sub_district,
    postal_code,
  } = initialValues;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 5,
      },
    },
  };

  const [createCustomer, { error, loading, data }] = useMutation(
    CREATE_CUSTOMER,
    { refetchQueries: [`customers`] }
  );

  const handleSubmit = (values) => {
    console.log(values);
    const val = {
      email: values.email,
      name: values.name,
      phone_number: values.phone_number,
      phone_number_2: values.phone_number_2,
      address: values.address,
      province: values.locations[0],
      district: values.locations[1],
      sub_district: values.sub_district,
      postal_code: values.postal_code,
    };
    createCustomer({
      variables: val,
    }).then((res) => {
      val.id = res.data.createCustomer.id;
      submit(val);
    });
  };

  return (
    <Spin spinning={loading}>
      {error && (
        <Alert
          message={JSON.stringify(error.graphQLErrors[0].debugMessage)}
          type="error"
          showIcon
        />
      )}
      <Form layout="horizontal" {...formItemLayout} onFinish={handleSubmit}>
        <Form.Item
          label="Name"
          name="name"
          initialValue={name}
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          initialValue={email ? "" + email : ""}
        >
          <Input disabled={editMode} type="email"></Input>
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone_number"
          initialValue={phone_number ? "" + phone_number : ""}
          rules={[{ required: true, message: "Phone number is required" }]}
        >
          <Input></Input>
        </Form.Item>

        <Form.Item
          label="Phone 2"
          name="phone_number_2"
          initialValue={phone_number_2 ? "" + phone_number_2 : ""}
        >
          <Input></Input>
        </Form.Item>

        <Form.Item
          label="Locations"
          name="locations"
          initialValue={
            province
              ? [province ? "" + province : "", district ? "" + district : ""]
              : []
          }
          rules={[
            {
              type: "array",
              required: true,
              message: "Please select your habitual residence!",
            },
            {
              validator: (rule, value, cb) => {
                if (value[0] === undefined || value[1] === undefined) {
                  cb("Please select your locations!");
                } else {
                  cb();
                }
              },
            },
          ]}
        >
          <LocationSelectMini />
        </Form.Item>
        <Form.Item
          label="Sub district"
          name="sub_district"
          initialValue={sub_district ? sub_district : ""}
        >
          <Input placeholder="Eg. kecamatan, kelurahan etc" />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          initialValue={address ? "" + address : ""}
        >
          <Input.TextArea></Input.TextArea>
        </Form.Item>

        <Form.Item
          label="Postal code"
          name="postal_code"
          initialValue={postal_code ? "" + postal_code : ""}
        >
          <Input type="number"></Input>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default New;
