import { gql } from '@apollo/client';

export const CREATE_COUPON = gql`
    mutation($name: String!, $key: String!, $type: String!, $amount_type: String!, $amount: Int!, $start_period: DateTime!, $end_period: DateTime!, $description: String) {
        createCoupon(input: {
            name: $name,
            key: $key,
            type: $type,
            amount_type: $amount_type,
            amount: $amount,
            start_period: $start_period,
            end_period: $end_period,
            description: $description,
        }){
            id
            name
            key
            type
            amount_type
            amount
            start_period
            end_period
            description
            enable
            created_at
            updated_at
        }
    }
`;

export const UPDATE_COUPON = gql`
    mutation($id: ID!, $enable: Boolean){
        updateCoupon(input: {
            id: $id,
            enable: $enable
        }){
            id
            key
            name
            type
            amount_type
            amount
            start_period
            end_period
            description
            enable
            created_at
            updated_at
        }
    }
`