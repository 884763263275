import { gql } from '@apollo/client';

export const USERS = gql`
  query($first: Int!, $page: Int!, $keyword: String, $branch_id: Int) {
    users(
      first: $first
      page: $page
      keyword: $keyword
      branch_id: $branch_id
    ) {
      paginationInfo {
        total
        lastPage
      }
      data {
        id
        email
        name
        phone_number
        branch {
          id
          name
        }
        role {
          id
          name
        }
        meta{
          id
          user_id
          key
          value
        }
      }
    }
  }
`;

export const USER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      email
      is_active
      name
      phone_number
      branch {
        id
        name
      }
      role {
        id
        name
      }
      meta{
        id
        user_id
        key
        value
      }
    }
  }
`;
