import React, { useState, useRef } from "react";
import { useQuery } from "react-query";
import qs from "qs";
import {
  Descriptions,
  DatePicker,
  Form,
  Modal,
  Button,
  Switch,
  Tag,
  Spin,
  Typography,
  Input,
  Select,
} from "antd";
import BranchSelect from "UI/Input/BranchSelect";
import TvSelect from "UI/Input/TvSelect";
import StatusSelect from "UI/Input/StatusSelect";
import ProductMultiSelect from "UI/Input/ProductMultiSelect";
import { useLazyQuery } from "@apollo/client";
import { REPORT_BY_PRODUCT } from "GraphQL/Queries";
import ProductDayTable from "./ProductDayTable";
import { useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const ProductsModal = (props) => {
  const { onOk, visible, selected: selectedProducts, onCancel } = props;
  let selected = selectedProducts.map((product) => product.id);
  const handleChange = (selectedProducts) => {
    selected = selectedProducts;
  };
  const [all, setAll] = useState(selected.length === 0);
  const handleOk = () => {
    if (all) {
      onOk([]);
    } else {
      onOk(selected);
    }
  };
  const handleAllProduct = (disabled) => {
    setAll(disabled);
  };

  return (
    <Modal
      onCancel={onCancel}
      open={visible}
      onOk={handleOk}
      title="Select products"
    >
      <ProductMultiSelect
        disabled={all}
        selected={selected}
        onChange={handleChange}
      />
      <Switch
        unCheckedChildren="All"
        checkedChildren="All"
        checked={all}
        onChange={handleAllProduct}
        style={{ marginTop: 16 }}
      />
    </Modal>
  );
};

const FilterForm = (props) => {
  const me = useSelector((state) => state.me);
  const [form] = Form.useForm();
  const [state, setState] = useState({
    productsModalVisible: false,
    selectedProducts: [],
  });
  const { productsModalVisible, selectedProducts } = state;
  const { submit } = props;
  const handleSubmit = (values) => {
    values.user_id = me.id;
    values.products = selectedProducts.map((product) => product.id);
    submit(values);
  };
  const handleDownloadButton = () => {
    form.setFieldValue("download", true);
  };

  const handleSubmitButton = () => {
    form.setFieldValue("download", false);
  };
  return (
    <React.Fragment>
      {productsModalVisible && (
        <ProductsModal
          selected={selectedProducts}
          visible={productsModalVisible}
          onOk={(products) =>
            setState({
              ...state,
              selectedProducts: products,
              productsModalVisible: false,
            })
          }
          onCancel={() => {
            setState({ ...state, productsModalVisible: false });
          }}
        />
      )}
      <Form onFinish={handleSubmit} form={form}>
        <Descriptions bordered title="Filter">
          <Descriptions.Item label="Date" span={1}>
            <Form.Item name="date_by" initialValue={"created_at"}>
              <Select>
                <Select.Option value="created_at">Order at</Select.Option>
                <Select.Option value="done_at">Done at</Select.Option>
              </Select>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item span={2}>
            <Form.Item
              name="dateRange"
              rules={[{ required: true, message: "Date range required" }]}
            >
              <RangePicker />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Branch">
            <Form.Item name="branch_id" initialValue="all">
              <BranchSelect />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="TV">
            <Form.Item name="tv_id" initialValue="all">
              <TvSelect hideall={false} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Inv Status">
            <Form.Item name="status" initialValue="ALL">
              <StatusSelect />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Products" span={2}>
            <Form.Item>
              {selectedProducts.map((product) => (
                <Tag key={product.id}>
                  ({product.id}) {product.name}
                </Tag>
              ))}{" "}
              {selectedProducts.length === 0 ? "All products " : ""}
              <Button
                onClick={() => {
                  setState({ ...state, productsModalVisible: true });
                }}
              >
                Select ...
              </Button>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item>
            <Button
              htmlType="submit"
              type="primary"
              onClick={handleSubmitButton}
            >
              Generate
            </Button>
            <Button
              htmlType="submit"
              type="link"
              icon={<DownloadOutlined />}
              onClick={handleDownloadButton}
            >
              Download
            </Button>
          </Descriptions.Item>
        </Descriptions>
        <Form.Item name="download" initialValue={false}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

const ByProduct = () => {
  const formDataRef = useRef({});
  const [downloadState, setDownloadState] = useState({
    loading: false,
  });
  const { isLoading, error, data, refetch } = useQuery(
    "report-by-product",
    () => {
      console.log(
        `${
          process.env.REACT_APP_SERVER_API_URL
        }/api/v1/reports/transactions/products?${qs.stringify(
          formDataRef.current
        )}`
      );
      return fetch(
        `${
          process.env.REACT_APP_SERVER_API_URL
        }/api/v1/reports/transactions/products?${qs.stringify(
          formDataRef.current
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ).then((res) => res.json());
    },
    {
      enabled: false,
    }
  );

  const downloadReport = async () => {
    setDownloadState({
      ...downloadState,
      loading: true,
    });
    const generateUrlResponse = await axios.post(
      `${process.env.REACT_APP_SERVER_API_URL}/api/v1/reports/transactions/products`,
      formDataRef.current,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    setDownloadState({
      ...downloadState,
      loading: false,
    });

    window.open(generateUrlResponse.data.url, "_self");
  };

  const handleSubmit = (params) => {
    const { dateRange, download, products, ...paramsRest } = params;
    if (paramsRest.branch_id === "all") {
      paramsRest.branch_id = null;
    } else {
      paramsRest.branch_id = Number(paramsRest.branch_id);
    }
    if (paramsRest.tv_id === "all") {
      paramsRest.tv_id = null;
    } else {
      paramsRest.tv_id = Number(paramsRest.tv_id);
    }
    if (paramsRest.status === "ALL") {
      paramsRest.status = null;
    }

    paramsRest.date_from = dateRange[0].format("YYYY-MM-DD");
    paramsRest.date_to = dateRange[1].format("YYYY-MM-DD");
    paramsRest.products_id = products;
    formDataRef.current = paramsRest;
    if (download) {
      downloadReport();
    } else {
      refetch();
    }
  };

  return (
    <React.Fragment>
      <Spin spinning={isLoading || downloadState.loading}>
        <FilterForm submit={handleSubmit} />
        {!isLoading &&
          data &&
          data.data.map((dt) => (
            <React.Fragment key={dt.created_at}>
              <br />
              <Descriptions bordered layout="vertical">
                <Descriptions.Item label={dt.created_at}>
                  {console.log(dt.created_at, dt.products)}
                  {dt.products.map((product) => (
                    <React.Fragment key={product.id}>
                      <Text strong>Product name: {product.name}</Text>
                      <ProductDayTable
                        product={product}
                        data={product.inventories}
                      />
                      <br />
                    </React.Fragment>
                  ))}
                </Descriptions.Item>
              </Descriptions>
              <br />
            </React.Fragment>
          ))}
      </Spin>
    </React.Fragment>
  );
};
export default ByProduct;
