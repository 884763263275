import { useState } from "react";
import { Table, Button, message, Typography } from "antd";
import { PageContainer } from "@ant-design/pro-components";
import { useQuery } from "react-query";
import { formatDate } from "Utils/Date";
import axios from "axios";
const { Text } = Typography;

const Products = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [page, setPage] = useState(1);
  const { isLoading, error, data, refetch } = useQuery(
    ["products-report", page],
    () =>
      fetch(
        `${process.env.REACT_APP_SERVER_API_URL}/api/v1/reports/products?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ).then((res) => res.json()),
    {
      refetchInterval: 5000,
    }
  );

  const showError = (record) => {};
  const generateNewReport = async () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_API_URL}/api/v1/reports/products`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
      .then(() => {
        refetch();
        messageApi.open({
          type: "success",
          content: "This is a success message",
        });
      })
      .catch((e) => {
        messageApi.open({
          type: "error",
          content: e.response.data.message,
        });
      });
  };
  const downloadReport = async (report) => {
    const data = await axios
      .get(
        `${process.env.REACT_APP_SERVER_API_URL}/api/v1/reports/${report.uuid}/download-link`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
      .then((response) => response.data);
    window.open(data, "_self");
  };
  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <Text type="secondary">{record.user.name}</Text>
        </div>
      ),
    },
    {
      key: "created_at",
      title: "Requested at",
      dataIndex: "created_at",
      render: (text, record) => <div>{formatDate(text, true)}</div>,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div>
          {record.status === "ERROR" ? (
            <Button
              style={{ padding: 0 }}
              type="link"
              danger
              onClick={() => showError(record)}
            >
              ERROR
            </Button>
          ) : (
            text
          )}
        </div>
      ),
    },
    {
      key: "action",
      title: "Action",
      render: (text, record) =>
        record.status === "READY" && (
          <Button onClick={() => downloadReport(record)}>Download</Button>
        ),
    },
  ];

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      {contextHolder}
      <PageContainer
        extra={[
          <Button type="primary" onClick={generateNewReport}>
            Generate new report
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          loading={isLoading}
          dataSource={data ? data.data : []}
          pagination={{
            pageSize: data ? data.per_page : 0,
            page: data ? data.current_page : 0,
            total: data ? data.total : 0,
            onChange: (page, i) => {
              setPage(page);
            },
          }}
        />
      </PageContainer>
    </>
  );
};

export default Products;
