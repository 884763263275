import React, { useState } from "react";
import { message, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-components" 
import BranchForm from "./BranchForm";
import { CREATE_BRANCH } from "GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

const Add = () => {
  const [redirect, setRedirect] = useState(false);
  const [createBranch, { error, loading, data }] = useMutation(CREATE_BRANCH);
  const handleSubmit = v => [
    createBranch({ variables: v })
      .then(() => {
        message.success("Branch created");
        setRedirect(true);
      })
      .catch(err => {
        message.error(err.message);
      })
  ];
  if (redirect) {
    return <Redirect to={`/branches`} />;
  }
  return (
    <React.Fragment>
      <PageHeader title="New branch" />
      <br />
      <Spin spinning={loading}>
        <BranchForm submit={handleSubmit} initialValues={{}} />
      </Spin>
    </React.Fragment>
  );
};

export default Add;
