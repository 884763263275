import { gql } from '@apollo/client';

export const CREATE_PRODUCT = gql`
  mutation(
    $category_id: Int
    $name: String
    $weight: Int
    $price: Int
    $minqty: Int
    $description: String
    $is_active: Int
  ) {
    createProduct(
      category_id: $category_id
      name: $name
      weight: $weight
      price: $price
      description: $description
      is_active: $is_active
      minqty: $minqty
    ) {
      id
      category_id
      name
      weight
      price
      description
      is_active
      stock
      created_at
      updated_at
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation(
    $id: ID!
    $name: String!
    $weight: Int!
    $price: Int!
    $description: String
    $category_id: Int!
    $minqty: Int!
    $is_active: Int!
  ) {
    updateProduct(
      id: $id
      name: $name
      weight: $weight
      price: $price
      description: $description
      category_id: $category_id
      minqty: $minqty
      is_active: $is_active
    ) {
      id
      category_id
      name
      weight
      price
      description
      is_active
      stock
      minqty
      created_at
      updated_at
    }
  }
`;

export const SET_ACTIVE_PRODUCT = gql`
  mutation($id: ID!, $is_active: Int!) {
    setProductActivate(id: $id, is_active: $is_active) {
      id
      category_id
      name
      weight
      price
      description
      is_active
      category {
        id
        name
      }
      minqty
      created_at
      updated_at
    }
  }
`;
