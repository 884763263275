import React, { useContext, useState } from "react";
import MainLayout from "Layouts/Main";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import ProductList from "Pages/Products/List";
import ProductAdd from "Pages/Products/Add";
import ProductEdit from "Pages/Products/Edit";
import Transactions from "Pages/Transaction/List";
import TransactionAdd from "Pages/Transaction/Add";
import TransactionView from "Pages/Transaction/View";
import TransactionPrint from "Pages/Transaction/Print";
import StocksMigrationPrint from "Pages/Stocks/Print";
import CustomersList from "Pages/Customers/List";
import CustomerAdd from "Pages/Customers/Add";
import CustomerEdit from "Pages/Customers/Edit";
import BranchList from "Pages/Branches/List";
import BranchEdit from "Pages/Branches/Edit";
import BranchAdd from "Pages/Branches/Add";
import BranchUserEdit from "Pages/Branches/UserEdit";
import BranchUserAdd from "Pages/Branches/UserAdd";
import BranchUsers from "Pages/Branches/UserList";
import UserAdd from "Pages/Users/Add";
import UserEdit from "Pages/Users/Edit";
import UserList from "Pages/Users/List";
import BuyView from "Pages/Buy/View";
import BuyList from "Pages/Buy/List";
import BuyAdd from "Pages/Buy/Add";
import Dashboard from "Pages/Dashboard/Dashboard";
import Entities from "Pages/Entities/Index";
import Stocks from "Pages/Stocks/List";
import StockMigrateView from "Pages/Stocks/View";
import StockMigrate from "Pages/Stocks/Migrate";
import CouponList from "Pages/Coupons/List";
import CouponAdd from "Pages/Coupons/Add";
import Reports from "Pages/Reports/List";
import PartnerReports from "Pages/PartnerReports/List";

import Login from "Layouts/Login";
import { Spin } from "antd";
import {
  DashboardOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  ShoppingOutlined,
  FolderAddOutlined,
  DatabaseOutlined,
  BranchesOutlined,
  BuildOutlined,
  AreaChartOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import apolloClient from "GraphQL/apollo";
import SecureRoute from "Helpers/SecureRoute";
import Secure from "Helpers/Secure";
// import "antd/dist/antd.css";
import "App.css";
import axios from "axios";
import { ME, ROLE_PERMISSION } from "GraphQL/Queries";
import { useSelector, useDispatch } from "react-redux";
import DomOutput from "Helpers/DomOutput";

function App(props) {
  const ready = useSelector((state) => state.ready);
  const me = useSelector((state) => state.me);
  const permissions = useSelector((state) => state.permissions);
  const dispatch = useDispatch();
  if (!ready) {
    if (props.location.pathname !== "/login") {
      axios
        .get(process.env.REACT_APP_LOCATIONS_URL)
        .then((res) => {
          dispatch({ type: "PUT_LOCATIONS", payload: res.data });
          return apolloClient.query({ query: ME });
        })
        .then((_data) => {
          const data = JSON.parse(JSON.stringify(_data));
          data.data.me.branch.id = Number(data.data.me.branch.id);
          data.data.me.id = Number(data.data.me.id);
          dispatch({ type: "PUT_ME", payload: data.data.me });
          return apolloClient.query({
            query: ROLE_PERMISSION,
            variables: { role_id: Number(data.data.me.role.id) },
          });
        })
        .then((role) => {
          const permissions = {};
          role.data.RolePermission.permissions.forEach(
            (perm) => (permissions[perm.name] = perm.permitted)
          );
          dispatch({ type: "PUT_PERMISSIONS", payload: permissions });
          dispatch({ type: "READY" });
        })
        .catch((err) => {
          console.error("axios error", err);
          window.location = "/login";
        });
    }
  }
  return (
    <Switch>
      <Route path="/login/" component={Login} />
      <SecureRoute path="/transaction/:id/print" component={TransactionPrint} />
      <SecureRoute path="/stocks/:id/print" component={StocksMigrationPrint} />
      {!ready ? (
        <Spin tip="Loading"></Spin>
      ) : (
        <MainLayout
          sideMenus={[
            <React.Fragment key="/">
              <DashboardOutlined /> <span>Dashboard</span>
              <Link to="/" />
            </React.Fragment>,
            permissions.CAN_VIEW_PRODUCT && !permissions.TV_READ_ONLY && (
              <React.Fragment key="/products">
                <AppstoreAddOutlined /> <span>Products</span>
                <Link to="/products" />
              </React.Fragment>
            ),
            permissions.CAN_VIEW_CUSTOMER && me.branch.id === 1 && (
              <React.Fragment key="/customers">
                <UserOutlined /> <span>Customers</span>
                <Link to="/customers" />
              </React.Fragment>
            ),
            permissions.CAN_VIEW_TRANSACTION_SELL &&
              !permissions.TV_READ_ONLY && (
                <React.Fragment key="/transactions">
                  <ShoppingOutlined /> <span>Transactions</span>
                  <Link to="/transactions" />
                </React.Fragment>
              ),
            permissions.CAN_VIEW_TRANSACTION_BUY &&
              !permissions.TV_READ_ONLY &&
              me.branch.id === 1 && (
                <React.Fragment key="/buy">
                  <FolderAddOutlined /> <span>Buy</span>
                  <Link to="/buy" />
                </React.Fragment>
              ),
            permissions.CAN_MIGRATE_STOCK && me.branch.id === 1 && (
              <React.Fragment key="/stocks">
                <DatabaseOutlined /> <span>Stocks Migration</span>
                <Link to="/stocks" />
              </React.Fragment>
            ),
            permissions.CAN_VIEW_USER && me.branch.id !== 1 && (
              <React.Fragment key="/users">
                <UserOutlined /> <span>Users</span>
                <Link to="/users" />
              </React.Fragment>
            ),
            <React.Fragment key="/coupons">
              <WalletOutlined /> <span>Coupons</span>
              <Link to="/coupons" />
            </React.Fragment>,
            permissions.TV_READ_ONLY && me.branch.id === 1 && (
              <React.Fragment key="/users">
                <UserOutlined /> <span>Partner reports</span>
                <Link to="/partner-reports" />
              </React.Fragment>
            ),
            permissions.CAN_VIEW_BRANCH && me.branch.id === 1 && (
              <React.Fragment key="/branches">
                <BranchesOutlined /> <span>Office Branches</span>
                <Link to="/branches" />
              </React.Fragment>
            ),
            permissions.CAN_UPDATE_SETTINGS && me.branch.id === 1 && (
              <React.Fragment key="/entities">
                <BuildOutlined /> <span>Entities</span>
                <Link to="/entities" />
              </React.Fragment>
            ),
            permissions.CAN_VIEW_REPORT && me.branch.id === 1 && (
              <React.Fragment key="/reports">
                <AreaChartOutlined /> <span>Reports</span>
                <Link to="/reports" />
              </React.Fragment>
            ),
          ]}
        >
          <Secure>
            <Switch>
              <SecureRoute exact path="/" component={Dashboard} />
              <SecureRoute path="/customers/add" component={CustomerAdd} />
              <SecureRoute
                path="/customers/:id/edit"
                component={CustomerEdit}
              />
              <SecureRoute path="/customers" component={CustomersList} />
              <SecureRoute path="/coupons/add/" component={CouponAdd} />
              <SecureRoute path="/coupons" component={CouponList} />
              <SecureRoute path="/products/add/" component={ProductAdd} />
              <SecureRoute path="/products/:id/edit" component={ProductEdit} />
              <SecureRoute path="/products/" component={ProductList} />
              <SecureRoute
                path="/transactions/add/"
                component={TransactionAdd}
              />
              <SecureRoute
                path="/transactions/:id/view"
                component={TransactionView}
              />
              <SecureRoute path="/transactions/" component={Transactions} />
              <SecureRoute path="/buy/add" component={BuyAdd} />
              <SecureRoute path="/buy/:id/view" component={BuyView} />
              <SecureRoute path="/buy/" component={BuyList} />
              <SecureRoute path="/stocks/migrate" component={StockMigrate} />
              <SecureRoute
                path="/stocks/:id/view"
                component={StockMigrateView}
              />
              <SecureRoute path="/stocks/" component={Stocks} />
              <SecureRoute path="/entities/" component={Entities} />
              <SecureRoute path="/users/add" component={UserAdd} />
              <SecureRoute path="/users/:id/edit" component={UserEdit} />
              <SecureRoute path="/users/" component={UserList} />
              <SecureRoute
                path="/branches/:branch_id/users/add"
                component={BranchUserAdd}
              />
              <SecureRoute
                path="/branches/:branch_id/users/:user_id/edit"
                component={BranchUserEdit}
              />
              <SecureRoute
                path="/branches/:branch_id/users"
                component={BranchUsers}
              />
              <SecureRoute path="/branches/add" component={BranchAdd} />
              <SecureRoute path="/branches/:id/edit" component={BranchEdit} />
              <SecureRoute path="/branches/" component={BranchList} />
              <SecureRoute path="/reports/" component={Reports} />
              <SecureRoute
                path="/partner-reports/"
                component={PartnerReports}
              />
            </Switch>
          </Secure>
          <DomOutput data={me} />
        </MainLayout>
      )}
    </Switch>
  );
}

export default withRouter(App);
