import React from "react";
import { ME } from "GraphQL/Queries";

import { useQuery } from "@apollo/client";
import { Redirect } from "react-router-dom";
import { Spin } from "antd";
const Secure = (props) => {
  const { data, loading, error } = useQuery(ME);
  if (error) {
    console.log("secure error", error);
    return <Redirect to="/login" />;
  }
  if (!loading && !data.me) {
    console.log("secure error", error);
    return <Redirect to="/login" />;
  }
  if (!localStorage.getItem("access_token")) {
    console.log("secure error", error);
    return <Redirect to="/login" />;
  }
  if (!loading && !error) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
  return <Spin />;
};

export default Secure;
