import React from "react";
import { TRANSACTION } from "GraphQL/Queries";
import { Descriptions, Spin, Table } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useQuery } from "@apollo/client";
const View = props => {
  const { id } = props.match.params;
  const { data, loading, error } = useQuery(TRANSACTION, {
    variables: { id }
  });
  if (loading) {
    return <Spin spinning={loading} />;
  }

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Name",
      dataIndex: "product.name",
      key: "name"
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      align: "right",
      key: "quantity"
    },
    {
      title: "Price",
      dataIndex: "price",
      align: "right",
      key: "price",
      render: (text, record) => (
        <span>{window.idCurrency.formatSimple(record.price)}</span>
      )
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      align: "right",
      key: "subtotal",
      render: (text, record) => (
        <span>
          {window.idCurrency.formatSimple(record.quantity * record.price)}
        </span>
      )
    }
  ];
  return (
    <React.Fragment>
      <PageHeader title="Buy Details" />
      <Descriptions size="small" bordered>
        <Descriptions.Item label="INV" span={1}>
          {data.transaction.invoice_number}
        </Descriptions.Item>
        <Descriptions.Item label="Supplier" span={2}>
          {data.transaction.seller.name}
        </Descriptions.Item>
        <Descriptions.Item label="Created at">
          {new Date(Date.parse(data.transaction.created_at)).toDateString()}
        </Descriptions.Item>
        <Descriptions.Item label="Created by">
          {data.transaction.agent.name}
        </Descriptions.Item>
      </Descriptions>
      <br />

      <Table
        pagination={false}
        columns={columns}
        dataSource={data.transaction.inventories}
      />
    </React.Fragment>
  );
};

export default View;
