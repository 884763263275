import React, { useEffect, useRef, useState } from "react";
import {
  Descriptions,
  Button,
  Form,
  InputNumber,
  Input,
  Space,
  Modal,
  Typography,
  Tag,
} from "antd";
import CourierSelect from "UI/Input/CourierSelect";
import BranchSelect from "UI/Input/BranchSelect";
import TvSelect from "UI/Input/TvSelect";
import Locations from "Helpers/Locations";
import CouponSelect from "UI/Input/CouponSelect";
import ItemsInput from "../../UI/Input/ItemsInput";
import _ from "lodash";
import { useSelector } from "react-redux";
import { processCoupon } from "Utils/Coupon";
const { Text } = Typography;

const CouponSelectModal = ({ open, onCancel, onOk }) => {
  const handleOk = () => {};
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (open && !initialized) {
      setInitialized(true);
    }
  }, [open, initialized]);
  return (
    <Modal
      title="Select a coupon"
      open={open}
      onOk={handleOk}
      onCancel={onCancel}
      footer={[
        <Button key="cancel_button" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      {initialized && <CouponSelect onSelect={(item) => onOk(item)} />}
    </Modal>
  );
};

function usePreviousDeep(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = _.cloneDeep(value);
  });
  return ref.current;
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const TransactionForm = (props) => {
  const {
    setCustomerModalOpen,
    customerModalOpen,
    setCustomerEditModalOpen,
    customerEditModalOpen,
    customer,
    submit = () => {},
    addItem,
    items,
    deleteItem,
  } = props;

  const [form] = Form.useForm();

  const [state, setState] = useState({
    branchId: useSelector((state) => state.me.branch.id),
  });
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const { branchId } = state;

  const clearCoupon = () => {
    console.log("clear coupon");
    setCoupon(null);
    form.setFieldValue("coupon_id", null);
  };

  const handleSubmit = (values) => {
    values.shipping_cost = Number(values.shipping_cost);
    submit(values);
  };
  const prevItems = usePreviousDeep(items);

  useEffect(() => {
    let val = {};
    if (!_.isEqual(items, prevItems)) {
      let count = 0;
      for (let i = 0; i < items.length; i++) {
        count += items[i].qty;
      }
      val.items_count = count;
    }

    if (customer.id !== form.getFieldValue("customer_id")) {
      val.customer_id = customer.id;
    }

    if (Object.keys(val).length > 0) {
      form.setFieldsValue(val);
    }
  });

  let subtotal = 0;
  let shippingCost = isNaN(Number(form.getFieldValue("shipping_cost")))
    ? 0
    : Number(form.getFieldValue("shipping_cost"));

  for (let i = 0; i < items.length; i++) {
    subtotal += items[i].qty * items[i].price;
  }
  let grandtotal = subtotal + shippingCost;
  let grandtotalCoupTotal =
    processCoupon(coupon, subtotal).price + shippingCost;
  let grandtotalCoupCut = processCoupon(coupon, subtotal).cut;

  return (
    <>
      <CouponSelectModal
        open={showCouponModal}
        onCancel={() => setShowCouponModal(false)}
        onOk={(_coupon) => {
          setCoupon(_coupon);
          setShowCouponModal(false);
          form.setFieldValue("coupon_id", _coupon.id);
        }}
      />
      <Form onFinish={handleSubmit} form={form}>
        <Descriptions bordered size="small" title="Customer">
          <Descriptions.Item label="Customer ID" span={3}>
            <Form.Item
              name="customer_id"
              initialValue={customer.id}
              rules={[
                {
                  required: true,
                  message: "Customer required",
                },
              ]}
            >
              <Input placeholder="Choose customer" readOnly />
            </Form.Item>

            <Form.Item>
              <Button
                size="small"
                onClick={(e) => setCustomerModalOpen(!customerModalOpen)}
              >
                Choose
              </Button>{" "}
              {customer.id ? (
                <Button
                  size="small"
                  onClick={(e) =>
                    setCustomerEditModalOpen(!customerEditModalOpen)
                  }
                >
                  Edit
                </Button>
              ) : null}
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Name">{customer.name}</Descriptions.Item>
          <Descriptions.Item label="Phone">
            {customer.phone_number}{" "}
            {customer.phone_number_2 && ` / ${customer.phone_number_2}`}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{customer.email}</Descriptions.Item>
          <Descriptions.Item label="Kecamatan">
            {customer.sub_district}
          </Descriptions.Item>
          <Descriptions.Item label="Kabupaten">
            {Locations.getCaption(customer.district)}
          </Descriptions.Item>
          <Descriptions.Item label="Provinsi">
            {Locations.getCaption(customer.province)}
          </Descriptions.Item>
          <Descriptions.Item label="Address" span={2}>
            {customer.address}
          </Descriptions.Item>
          <Descriptions.Item label="Postcode">
            {customer.postal_code}
          </Descriptions.Item>
        </Descriptions>
        <br />

        <Descriptions title="Additional" bordered size="small">
          <Descriptions.Item label="Assignee" span={3}>
            <Form.Item
              name="branch_id"
              initialValue={branchId}
              rules={[
                {
                  required: true,
                  message: "Branch assignee is required",
                },
              ]}
            >
              <BranchSelect
                onChange={(val) =>
                  setState({
                    ...state,
                    branchId: val,
                  })
                }
                hideall
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Courier">
            <Form.Item
              name="courier_id"
              rules={[
                {
                  required: true,
                  message: "Courier required",
                },
              ]}
            >
              <CourierSelect />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Shipping">
            <Form.Item name="shipping_cost" initialValue={0}>
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => window.idCurrency.formatSimple(value)}
                parser={(value) => window.idCurrency.parseSimple(value)}
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Tv">
            <Form.Item
              name="tv_id"
              rules={[
                {
                  required: true,
                  message: "TV is required",
                },
              ]}
            >
              <TvSelect />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Descriptions bordered title="Summary">
          <Descriptions.Item label="Items" span={2}>
            <Form.Item
              name="items_count"
              initialValue="0"
              rules={[
                {
                  validator: (rule, value, cb) => {
                    if (Number(value) > 0) {
                      cb();
                    } else {
                      cb("Cart cannot be empty");
                    }
                  },
                },
              ]}
            >
              <Input addonAfter="Item(s)" readOnly />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Coupon" span={2}>
            <Space.Compact>
              <Input
                placeholder="No coupon selected"
                readOnly={true}
                value={coupon ? coupon.key : ""}
              />
              <Button type="primary" onClick={() => setShowCouponModal(true)}>
                Select...
              </Button>
              <Button onClick={() => clearCoupon()}>Clear</Button>
            </Space.Compact>
            <div>
              {coupon ? (
                <Tag color="green">
                  {coupon.amount_type === "PERCENTAGE" &&
                    `-${coupon.amount}% off`}
                  {coupon.amount_type === "FIXED" &&
                    `-${window.idCurrency.format(coupon.amount)}`}
                </Tag>
              ) : (
                ""
              )}
              {coupon ? coupon.description : ""}
              {coupon ? (
                coupon.description ? (
                  coupon.description
                ) : (
                  <em>Coupon has no description</em>
                )
              ) : (
                ""
              )}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Subtotal">
            {processCoupon(coupon, subtotal).cut > 0 && (
              <>
                <Text delete>{window.idCurrency.format(subtotal)}</Text>{" "}
                <Tag color="green">
                  -&nbsp;
                  {window.idCurrency.format(
                    processCoupon(coupon, subtotal).cut
                  )}
                  &nbsp;off
                </Tag>{" "}
              </>
            )}
            {window.idCurrency.format(processCoupon(coupon, subtotal).price)}
          </Descriptions.Item>
          <Descriptions.Item label="Grandtotal">
            {grandtotalCoupCut > 0 && (
              <>
                <Text delete>{window.idCurrency.format(grandtotal)}</Text>{" "}
                <Tag color="green">
                  -&nbsp;
                  {window.idCurrency.format(grandtotalCoupCut)}
                  &nbsp;off
                </Tag>{" "}
              </>
            )}
            {window.idCurrency.format(grandtotalCoupTotal)}
          </Descriptions.Item>
        </Descriptions>
        <Form.Item
          initialValue={null}
          name="coupon_id"
          hidden={true}
        ></Form.Item>
        <br />
        <ItemsInput
          branchId={branchId}
          add={addItem}
          items={items}
          deleteItem={deleteItem}
          coupon={coupon}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default TransactionForm;
