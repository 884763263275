import { gql } from '@apollo/client';

export const MIGRATE_STOCK = gql`
  mutation($migrate_stock: MigrateStock!) {
    migrateStock(input: $migrate_stock) {
      status
      message
    }
  }
`;
