import React, { useState, useEffect } from "react";
import { Transfer, Spin, Form, Input } from "antd";
import { useQuery } from "@apollo/client";
import { PRODUCTS } from "GraphQL/Queries";

const ProductMultiSelect = (props) => {
  const { onChange, selected = [], disabled = false } = props;
  const [globalLoading, setGlobalLoading] = useState(false);
  const [state, setState] = useState({
    targetKeys: selected,
    selectedKeys: [],
  });
  const [dataSource, setDataSource] = useState([]);

  const { data, loading, error, refetch } = useQuery(PRODUCTS, {
    variables: { count: 10, page: 1 },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data && !loading) {
      const mergedData = dataSource.concat(
        data.products.data.filter((a) => !dataSource.find((b) => b.id === a.id))
      );
      setDataSource([...mergedData]);
    }
  }, [data, loading]);

  const dataSrc = dataSource.map((product) => {
    return {
      key: product.id,
      title: `${product.id} ${product.name}`,
    };
  });

  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    setState({ ...state, targetKeys: nextTargetKeys });
    const selected = data.products.data.filter((product) => {
      return nextTargetKeys.indexOf(product.id) > -1;
    });
    onChange(selected);
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setState({
      ...state,
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  const { targetKeys, selectedKeys } = state;

  const handleOnFinish = async (values) => {
    const { keyword } = values;
    setGlobalLoading(true);
    await refetch({
      keyword,
    });
    setGlobalLoading(false);
  };
  return (
    <Spin spinning={loading || globalLoading}>
      <div>
        <Form onFinish={handleOnFinish}>
          <Form.Item name="keyword">
            <Input.Search placeholder="Search for product here..." />
          </Form.Item>
        </Form>
        <Transfer
          style={{ width: "100%" }}
          dataSource={dataSrc}
          titles={["Source", "Target"]}
          showSearch
          listStyle={{
            width: 210,
            height: 300,
          }}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={handleChange}
          onSelectChange={handleSelectChange}
          render={(item) => item.title}
          disabled={disabled}
          filterOption={(inputValue, item) => {
            return item.title.toLowerCase().includes(inputValue.toLowerCase());
          }}
        />
      </div>
    </Spin>
  );
};

export default ProductMultiSelect;
