import React, { useState } from "react";
import { Table, Spin, Button } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { STOCK_MIGRATIONS } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { FileAddOutlined } from "@ant-design/icons";
const List = () => {
  const [state, setState] = useState({
    pageNumber: 1
  });

  const { pageNumber } = state;

  const { data, loading, error } = useQuery(STOCK_MIGRATIONS, {
    variables: { page: pageNumber, count: 10 },
    fetchPolicy: "cache-and-network"
  });
  let pageTotal = 0;
  if (loading) {
    return <Spin spinning={loading} />;
  }
  console.log("data", data);
  pageTotal = data.migrateStockList.paginationInfo.total;
  const columns = [
    {
      title: "INV",
      dataIndex: "invoice_number",
      key: "invoice_number",
      render: (text, record) => (
        <React.Fragment>
          <span>
            <Link to={`/stocks/${record.id}/view`}>
              {record.invoice_number}
            </Link>
          </span>
          <br />
          <span>
            on {new Date(Date.parse(record.created_at)).toDateString()}
          </span>
          <br />
          <span>by {record.agent.name}</span>
        </React.Fragment>
      )
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
      render: (text, record) => <span>{record.buyer.name}</span>
    }
  ];
  return (
    <React.Fragment>
      <PageHeader
        title="Stock Migrations"
        extra={[
          <Link to="/stocks/migrate">
            <Button icon={<FileAddOutlined />} key="addMigration" type="primary">
              Add
            </Button>
          </Link>
        ]}
      />
      <Table
        rowKey={record => record.id}
        pagination={{
          total: pageTotal,
          onChange: (page, pageSize) => setState({ ...state, pageNumber: page })
        }}
        dataSource={data.migrateStockList.data}
        columns={columns}
      />
    </React.Fragment>
  );
};

export default List;
