import { gql } from '@apollo/client';

export const CREATE_CATEGORY = gql`
  mutation($parent_category: Int!, $name: String) {
    createCategory(parent_category: $parent_category, name: $name) {
      id
      parent_category
      name
      created_at
      updated_at
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation($id: ID!, $parent_category: Int!, $name: String!) {
    updateCategory(id: $id, parent_category: $parent_category, name: $name) {
      id
      parent_category
      name
    }
  }
`;
