import React, { forwardRef } from "react";
import { Select, Spin } from "antd";
import { SUPPLIERS } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
const SupplierSelect = forwardRef((props, ref) => {
  const { data, loading, error } = useQuery(SUPPLIERS, {
    fetchPolicy: 'cache-and-network',
    variables: { page: 1, count: 999999 }
  });
  return (
    <Spin spinning={loading}>
      <Select {...props} ref={ref} style={{ minWidth: "150px" }}>
        {!loading &&
          data.suppliers.data.map(supplier => (
            <Select.Option value={supplier.id} key={supplier.id}>
              {supplier.name}
            </Select.Option>
          ))}
      </Select>
    </Spin>
  );
});

export default SupplierSelect;
