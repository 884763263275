import React from "react";

const DomOutput = props => {
  console.log(process.env.REACT_APP_SHOW_DEBUG === "1");
  return process.env.REACT_APP_SHOW_DEBUG === "1" ? (
    <div
      style={{
        border: "1px solid #ffe58f",
        backgroundColor: "#fffbe6",
        padding: "10px",
        borderRadius: "4px"
      }}
    >
      <p>Debug output is enabled</p>
      <pre style={{ color: "rgba(0,0,0,0.65)" }}>
        {JSON.stringify(props.data, null, 2)}
      </pre>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default DomOutput;
