import { gql } from '@apollo/client';

export const PRODUCTS = gql`
  query getProducts(
    $keyword: String
    $category_id: Int
    $page: Int!
    $count: Int!
    $branch_id: Int
    $insufficient_stock_only: Boolean
    $is_inactive: Boolean
    $is_active: Boolean
  ) {
    products(
      keyword: $keyword
      page: $page
      count: $count
      category_id: $category_id
      branch_id: $branch_id
      insufficient_stock_only: $insufficient_stock_only
      is_inactive: $is_inactive
      is_active: $is_active
    ) {
      paginationInfo {
        total
        lastPage
      }
      data {
        id
        name
        price
        weight
        stock
        description
        is_active
        minqty
        category {
          id
          name
        }
      }
    }
  }
`;

export const PRODUCT = gql`
  query($id: ID!) {
    product(id: $id) {
      id
      category_id
      name
      weight
      price
      description
      is_active
      stock
      created_at
      updated_at
    }
  }
`;
