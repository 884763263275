/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { withRouter, Redirect } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Button, Popover, Divider } from "antd";
import {UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ME } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import Logo from "iberkah_logo.png";
const { Header, Content, Sider } = Layout;
const layoutStyle = css`
  margin-top: 62px;
  @media (min-width: 992px) {
    margin-left: 200px;
    padding: 0 24px 24px;
  }
  min-height: 80vh;
`;

const MainLayout = (props) => {
  const { pathname } = props.location;
  const [toDashboard, setToDashboard] = useState(false);
  const [userVisible, setUserVisible] = useState(false);
  const {
    data: userData,
    loading: userLoading,
    error: userLoadingError,
  } = useQuery(ME);
  if (userLoadingError) {
    console.log("userLoadingError", userLoadingError);
    return <Redirect to="/login/" />;
  }
  const logout = () => {
    localStorage.removeItem("access_token");
    setToDashboard(true);
  };

  if (toDashboard) {
    window.location.href = "/login";
  }

  return (
    <Layout>
      <Header
        className="header"
        style={{ position: "fixed", zIndex: 101, width: "100%" }}
      >
        <div className="logo">
          <img style={{ height: "60%" }} src={Logo} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Popover
            content={
              <div>
                {!userLoading && (
                  <div>
                    {userData.me.role.name}@{userData.me.branch.name}
                  </div>
                )}
                <Button type="link" onClick={() => logout()}>Logout</Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => setUserVisible(false)}>Close</Button>
              </div>
            }
            title="User info"
            trigger="click"
            open={userVisible}
            placement="bottom"
            onOpenChange={(visible) => {
              setUserVisible(visible);
            }}
          >
            <Button type="primary">
              {userLoading ? (
                <span>Loading...</span>
              ) : (
                <span>
                  <UserOutlined /> {userData.me.name}
                </span>
              )}
            </Button>
          </Popover>
        </div>
      </Header>
      <Layout>
        <Sider
          width={200}
          breakpoint="lg"
          collapsedWidth="0"
          style={{
            height: "100vh",
            position: "fixed",
            left: 0,
            paddingTop: "60px",
            zIndex: 100,
          }}
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[pathname]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%", borderRight: 0, zIndex: 10 }}
            items={props.sideMenus.filter(sideMenu => !!sideMenu).map((sideMenu) => {
              return {
                key: sideMenu.key,
                label: sideMenu
              }
            })}
          />
        </Sider>
        <Layout css={layoutStyle}>
          <Breadcrumb style={{ margin: "16px 0" }} items={
            (() => {
              const data = [];
              data.push({
                title: "home",
                path: '/'
              })
              const paths = pathname.split("/").filter(path => path !== '').map((path, i) => {
                if(i > 0){
                  return {
                    title: path,
                  }
                }
                return {
                  title: path,
                  path: `/${path}`
                }
            })
              return [
                ...data, ...paths
              ]
            })()
            } 
            itemRender={(item) => {
              return (
                <a href={item.path}>{item.title}</a>
              )
            }}/>
          <Content
            style={{
              background: "#fff",
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
