import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { PRODUCTS } from "GraphQL/Queries";
import { SET_ACTIVE_PRODUCT } from "GraphQL/Mutations";
import {
  Input,
  Row,
  Col,
  Table,
  Button,
  Descriptions,
  Typography,
} from "antd";
import CategorySelect from "UI/Input/CategorySelect";
const { Text } = Typography;

const ProductSelect = props => {
  const { add, branchId } = props;
  const [state, setState] = useState({
    pageNumber: 0,
    keyword: "",
    categoryId: 0,
    insufficientStockOnly: false,
    inActiveOnly: null
  });
  const {
    pageNumber,
    keyword,
    categoryId,
    insufficientStockOnly,
    inActiveOnly
  } = state;

  const [setProductActive, { data: productActiveResult }] = useMutation(
    SET_ACTIVE_PRODUCT
  );
  let pageTotal = 0;
  const { loading, error, data, variables, networkStatus } = useQuery(
    PRODUCTS,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        page: pageNumber,
        count: 10,
        keyword,
        category_id: categoryId,
        branch_id: Number(branchId),
        insufficient_stock_only: insufficientStockOnly,
        is_active: true
      }
    }
  );
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div>
          {record.is_active ? (
            <Text>{record.name} </Text>
          ) : (
            <Text disabled>{record.name} </Text>
          )}
          <br />
          <span>Cat: {record.category.name}</span>
          <br />
          <span>
            {record.stock ? (
              record.stock < record.minqty ? (
                <Text type="danger">Stock: {record.stock}</Text>
              ) : (
                <Text>Stock: {record.stock}</Text>
              )
            ) : (
              <Text type="danger">Stock: 0</Text>
            )}
          </span>
        </div>
      )
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: value => window.idCurrency.format(value)
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Button
            size="small"
            onClick={() => {
              let qty = Number(prompt("quantity", "1"));
              if (isNaN(qty)) {
                qty = 1;
              }
              add(record, qty);
            }}
          >
            Add
          </Button>
        </span>
      )
    }
  ];
  if (!loading) {
    pageTotal = data.products.paginationInfo.total;
  }
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
      <Descriptions bordered layout="vertical">
        <Descriptions.Item label="Search">
          <Input
            style={{ width: "130px" }}
            allowClear
            placeholder="eg. Blender"
            onPressEnter={e => {
              e.preventDefault();
              setState({
                ...state,
                keyword: e.target.value,
                pageNumber: 1
              });
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Category">
          <CategorySelect
            small
            onChange={value => {
              setState({
                ...state,
                categoryId: Number(value),
                pageNumber: 1
              });
            }}
          />
        </Descriptions.Item>
      </Descriptions>

      </Col>
      <Col span={24}>
      <Table
        size="small"
        rowKey={record => record.id}
        columns={columns}
        dataSource={loading ? [] : data.products ? data.products.data : []}
        loading={loading}
        pagination={{
          total: pageTotal,
          onChange: (page, pageSize) => setState({ ...state, pageNumber: page })
        }}
      />
      </Col>
    </Row>
  );
};

export default ProductSelect;
