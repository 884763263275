import React, { useState } from "react";
import { Form, Descriptions, Input, Button } from "antd";
import BranchSelect from "UI/Input/BranchSelect";
import ItemsInput from "UI/Input/ItemsInput";
import { useSelector } from "react-redux";
const StockMigrationForm = props => {
  const me = useSelector(state => state.me);
  const [form] = Form.useForm()
  const { submit } = props;
  const [state, setState] = useState({
    items: [],
    branchId: me.branch.id
  });
  const { items, branchId } = state;
  const handleSubmit = values => {
      const { items_count, ...restValues } = values;
      const itemsMapped = items.map(item => {
        return {
          product_id: item.id,
          quantity: item.qty,
          price: item.price
        };
      });
      restValues.inventories = {};
      restValues.inventories.create = itemsMapped;
      submit(restValues);
  };

  const deleteItem = index => {
    let found = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === index) {
        found = "" + i;
      }
    }
    items.splice(found, 1);
    setState({
      ...state,
      items
    });
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      total += items[i].qty;
    }
    form.setFieldsValue({
      items_count: total
    });
  };
  const addItem = (item, quantity) => {
    let found = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === item.id) {
        found = "" + i;
      }
    }
    if (found) {
      if (items[found].qty < item.stock) {
        items[found].qty += quantity;
      }
    } else {
      if (item.stock > 0) {
        item.qty = quantity;
        items.push(item);
      }
    }
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      total += items[i].qty;
    }
    form.setFieldsValue({
      items_count: total
    });
    setState({
      ...state,
      items
    });
  };
  return (
    <Form onFinish={handleSubmit} form={form}>
      <Descriptions bordered size="small">
        <Descriptions.Item label="Migrate to branch">
          <Form.Item name="buyer_id" rules={[
                { required: true, message: "Branch destination required" }
              ]}>
              
            <BranchSelect hideall hideSelf />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Item Quantity">
          <Form.Item name="items_count" initialValue={0}
              rules={[
                { required: true, message: "At least one item" },
                {
                  validator: (rules, value, callback) => {
                    if (Number(value) > 0) {
                      callback();
                    } else {
                      callback("At least one item");
                    }
                  }
                }
              ]}>
            <Input readOnly />
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
      <br />
      <ItemsInput
        hidePrice
        deleteItem={deleteItem}
        add={addItem}
        items={items}
        branchId={branchId}
      />
      <br />
      <Button type="primary" htmlType="submit">
        Migrate
      </Button>
    </Form>
  );
};

export default StockMigrationForm;
