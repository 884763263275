import { Form, Input, InputNumber, Select, DatePicker, Button } from "antd";

const { RangePicker } = DatePicker;

const CouponForm = ({ submit, form }) => {
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 6,
      span: 18,
    },
  };

  const handleFormSubmit = (value) => {
    submit(value);
  };

  return (
    <Form
      form={form}
      onFinish={handleFormSubmit}
      {...layout}
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item
        name="name"
        label="name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="key" label="Code" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="type" hidden={true} initialValue={"ALL_PRODUCT"}>
        <Input />
      </Form.Item>
      <Form.Item
        name="amount_type"
        label="Amount Type"
        rules={[{ required: true }]}
      >
        <Select
          options={[
            {
              value: "FIXED",
              label: "Fixed",
            },
            {
              value: "PERCENTAGE",
              label: "Percentage",
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="amount"
        label="Amount"
        rules={[{ required: true }]}
        initialValue={0}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item name="period" label="Period" rules={[{ required: true }]}>
        <RangePicker />
      </Form.Item>
      <Form.Item name="description" label="Descriptions">
        <Input.TextArea />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CouponForm;
