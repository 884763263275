import React from "react";
import { Table, Typography } from "antd";
const { Text } = Typography;

const ProductDayTable = props => {
  const { data = [], product = {} } = props;
  const columns = [
    {
      title: "INV",
      dataIndex: "invoice_number",
      key: "invoicenumber",
      render: (text, record) => <span>{record.transaction.invoice_number}</span>
    },
    {
      title: "TV",
      dataIndex: "tv",
      key: "tvstation",
      render: (text, record) => <span>{record.transaction.tv.name}</span>
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (text, record) => <span>{record.transaction.seller.name}</span>
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      render: (text, record) => <span>{record.quantity}</span>
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (text, record) => (
        <span>{window.idCurrency.formatSimple(record.price)}</span>
      )
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      align: "right",
      render: (text, record) => (
        <span>
          {window.idCurrency.formatSimple(record.quantity * record.price)}
        </span>
      )
    }
  ];
  return (
    <React.Fragment>
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        rowKey={record => record.id}
        pagination={false}
        footer={record => {
          let qtyTotal = 0;
          let grandtotal = 0;
          for (let i = 0; i < record.length; i++) {
            qtyTotal += record[i].quantity;
            grandtotal += record[i].quantity * record[i].price;
          }
          return (
            <div style={{ textAlign: "right" }}>
              Total Qty: {qtyTotal}, grandtotal:{" "}
              {window.idCurrency.formatSimple(grandtotal)}
            </div>
          );
        }}
      />
    </React.Fragment>
  );
};

export default ProductDayTable;
