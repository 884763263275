import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { PRODUCTS } from "GraphQL/Queries";
import { SET_ACTIVE_PRODUCT } from "GraphQL/Mutations";
import {
  Input,
  Table,
  Form,
  Button,
  Divider,
  Checkbox,
  Typography,
  Tabs,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import CategorySelect from "UI/Input/CategorySelect";
import BranchSelect from "UI/Input/BranchSelect";
import { Link } from "react-router-dom";
import DomOutput from "Helpers/DomOutput";
import Categories from "./Categories";
import { useSelector } from "react-redux";
import { FileAddOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { TabPane } = Tabs;

const List = () => {
  const me = useSelector((state) => state.me);
  const permissions = useSelector((state) => state.permissions);
  const [state, setState] = useState({
    pageNumber: 0,
    keyword: "",
    categoryId: 0,
    branchId: null,
    insufficientStockOnly: false,
    inActiveOnly: null,
  });
  const {
    pageNumber,
    keyword,
    categoryId,
    branchId,
    insufficientStockOnly,
    inActiveOnly,
  } = state;

  //   const [pageNumber, setPageNumber] = useState(0);
  //   const [keyword, setKeyword] = useState("");
  //   const [categoryId, setCategoryId] = useState("0");
  //   const [branchId, setBranchId] = useState(null);
  //   const [InsufficientStockOnly, setInsufficientStockOnly] = useState(false);
  //   const [inActiveOnly, setInActiveOnly] = useState(null);
  const [setProductActive, { data: productActiveResult }] = useMutation(
    SET_ACTIVE_PRODUCT,
    {
      refetchQueries: ["getProducts"],
      awaitRefetchQueries: true,
    }
  );
  let pageTotal = 0;
  const { loading, error, data, variables, networkStatus } = useQuery(
    PRODUCTS,
    {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      variables: {
        page: pageNumber,
        count: 10,
        keyword,
        category_id: categoryId,
        branch_id: branchId,
        insufficient_stock_only: insufficientStockOnly,
        is_inactive: Boolean(inActiveOnly),
      },
    }
  );
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) =>
        record.is_active ? (
          <Text>{record.name}</Text>
        ) : (
          <Text disabled>{record.name}</Text>
        ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (value) => window.idCurrency.format(value),
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      render: (text, record) => (
        <span>
          {record.stock ? (
            record.stock < record.minqty ? (
              <Text type="danger">{record.stock}</Text>
            ) : (
              record.stock
            )
          ) : (
            <Text type="danger">0</Text>
          )}
        </span>
      ),
    },
    {
      title: "category",
      dataIndex: "category",
      key: "category",
      render: (text, record) => <span>{record.category.name}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/products/${record.id}/edit`}>Edit</Link>
          <Divider type="vertical" />
          <span>{record.is_active}</span>
          {record.is_active ? (
            <Button
              type="link"
              onClick={() =>
                setProductActive({ variables: { id: record.id, is_active: 0 } })
              }
            >
              deactivate
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() =>
                setProductActive({ variables: { id: record.id, is_active: 1 } })
              }
            >
              activate
            </Button>
          )}
        </span>
      ),
    },
  ];
  if (!loading) {
    console.log("products data", data);
    pageTotal = data.products.paginationInfo.total;
  }
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Products" key="1">
        <PageHeader
          title="Products"
          extra={
            permissions.CAN_UPDATE_PRODUCT &&
            me.branch.id === 1 && [
              <Link to="/products/add" key="add">
                <Button type="primary" icon={<FileAddOutlined />}>
                  {" "}
                  Add
                </Button>
              </Link>,
            ]
          }
        />
        <Form layout="inline">
          <Form.Item label="Search">
            <Input
              allowClear
              placeholder="eg. Blender"
              onPressEnter={(e) => {
                setState({
                  ...state,
                  keyword: e.target.value,
                  pageNumber: 1,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Category">
            <CategorySelect
              onChange={(value) => {
                setState({
                  ...state,
                  categoryId: Number(value),
                  pageNumber: 1,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Branch">
            <BranchSelect
              hideall
              onChange={(value) => {
                setState({
                  ...state,
                  branchId: Number(value),
                  pageNumber: 1,
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              onChange={(e) => {
                setState({
                  ...state,
                  insufficientStockOnly: e.target.checked,
                  pageNumber: 1,
                });
              }}
              style={{ minWidth: 150 }}
            >
              &nbsp;Out&nbsp;of&nbsp;stock&nbsp;only
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              value="1"
              onChange={(e) => {
                if (e.target.checked) {
                  setState({
                    ...state,
                    inActiveOnly: 1,
                    pageNumber: 1,
                  });
                } else {
                  setState({
                    ...state,
                    inActiveOnly: null,
                    pageNumber: 1,
                  });
                }
              }}
              style={{ minWidth: 150 }}
            >
              &nbsp;Inactive&nbsp;only
            </Checkbox>
          </Form.Item>
        </Form>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={loading ? [] : data.products ? data.products.data : []}
          loading={loading}
          pagination={{
            total: pageTotal,
            onChange: (page, pageSize) =>
              setState({ ...state, pageNumber: page }),
          }}
        />

        {<DomOutput data={{ variables: variables, data }} />}
      </TabPane>
      <TabPane tab="Categories" key="2">
        <Categories />
      </TabPane>
    </Tabs>
  );
};

export default List;
