import React, { useState } from "react";
import { COURIERS } from "GraphQL/Queries";
import { UPDATE_COURIER, CREATE_COURIER } from "GraphQL/Mutations";
import { useQuery, useMutation } from "@apollo/client";
import { List, Button, Form, Modal, Input, Checkbox, Typography } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { FileAddOutlined } from "@ant-design/icons";
const { Text } = Typography;

const CourierModal = (props) => {
  const [form] = Form.useForm();
  const { visible, onCancel, name, submit, loading, title, okText, enable } =
    props;
  const handleSubmit = (values) => {
    submit(values);
  };
  const handleModalSubmit = () => {
    form.submit();
  };
  return (
    <Modal
      open={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      onOk={handleModalSubmit}
      confirmLoading={loading}
    >
      <Form layout="inline" onFinish={handleSubmit} form={form}>
        <Form.Item
          label="name"
          name="name"
          initialValue={name ? name : ""}
          rules={[
            {
              required: true,
              message: "name required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="enable"
          name="enable"
          valuePropName="checked"
          initialValue={enable}
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Courier = () => {
  const { error, loading, data, refetch } = useQuery(COURIERS);

  const [
    updateCourier,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_COURIER, {
    refetchQueries: [`couriers`],
    awaitRefetchQueries: true,
  });

  const [
    createCourier,
    { data: createData, loading: createLoading, error: createError },
  ] = useMutation(CREATE_COURIER, {
    refetchQueries: "couriers",
    awaitRefetchQueries: true,
  });

  const [state, setState] = useState({
    modalVisible: false,
    editId: null,
    name: "",
    editMode: false,
    modalLoading: false,
    enable: false,
  });
  const { modalVisible, editId, name, editMode, modalLoading, enable } = state;

  return (
    <React.Fragment>
      {modalVisible && (
        <CourierModal
          visible={modalVisible}
          title={editMode ? "Edit Courier" : "New Courier"}
          name={name}
          enable={enable}
          okText={editMode ? "Update" : "Create"}
          onCancel={() => {
            setState({
              ...state,
              modalVisible: false,
            });
          }}
          loading={modalLoading}
          submit={(val) => {
            setState({
              ...state,
              modalLoading: true,
            });
            if (editMode) {
              updateCourier({
                variables: { name: val.name, enable: val.enable, id: editId },
              }).then(() => {
                setState({
                  ...state,
                  modalLoading: false,
                  modalVisible: false,
                });
              });
            } else {
              createCourier({ variables: { name: val.name } })
                .then(() => {
                  return refetch();
                })
                .then(() => {
                  setState({
                    ...state,
                    modalVisible: false,
                    modalLoading: false,
                  });
                });
            }
          }}
        />
      )}

      <PageHeader
        extra={[
          <Button
            key="addTv"
            type="primary"
            icon={<FileAddOutlined />}
            onClick={() => {
              setState({
                ...state,
                editMode: false,
                modalVisible: true,
                name: "",
              });
            }}
          >
            Add
          </Button>,
        ]}
      />
      <br />
      <List
        size="small"
        bordered
        loading={loading}
        dataSource={loading ? [] : data.couriers}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                size="small"
                key={item.id}
                type="primary"
                onClick={() => {
                  setState({
                    ...state,
                    modalVisible: true,
                    editId: item.id,
                    name: item.name,
                    enable: item.enable,
                    editMode: true,
                  });
                }}
              >
                Edit
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <Text disabled={!item.enable}>
                  {item.name} {item.enable ? "" : "(Disabled)"}
                </Text>
              }
            />
          </List.Item>
        )}
      />
    </React.Fragment>
  );
};

export default Courier;
