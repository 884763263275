import React, { useRef, useEffect } from "react";
import _ from "lodash";
import { Form, Descriptions, Button, Input } from "antd";
import SupplierSelect from "UI/Input/SupplierSelect";
import CourierSelect from "UI/Input/CourierSelect";
import ItemsInput from "UI/Input/ItemsInput";

function usePreviousDeep(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = _.cloneDeep(value);
  });
  return ref.current;
}

const BuyForm = props => {
  const { submit, branchId, addItem, items, deleteItem } = props;
  const [form] = Form.useForm();
  const handleSubmit = values => {
      submit(values);
  };

  const prevItems = usePreviousDeep(items);

  useEffect(() => {
    let val = {};
    if (!_.isEqual(items, prevItems)) {
      let count = 0;
      for (let i = 0; i < items.length; i++) {
        count += items[i].qty;
      }
      val.items_count = count;
    }

    if (Object.keys(val).length > 0) {
      form.setFieldsValue(val);
    }
  });

  let subtotal = 0;
  for (let i = 0; i < items.length; i++) {
    subtotal += items[i].qty * items[i].price;
  }
  return (
    <Form onFinish={handleSubmit} form={form}>
      <Descriptions title="Info" bordered>
        <Descriptions.Item label="Supplier">
          <Form.Item name="supplier_id" rules={[{ required: true, message: "Choose supplier" }]}>
             <SupplierSelect />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Courier">
          <Form.Item name="courier_id" rules={[{ required: true, message: "Choose courier" }]}>
            <CourierSelect />
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Summary" bordered>
        <Descriptions.Item label="Items">
          <Form.Item name="items_count" initialValue="0"
              rules={[
                {
                  validator: (rule, value, cb) => {
                    if (Number(value) > 0) {
                      cb();
                    } else {
                      cb("Item cannot be empty");
                    }
                  }
                }
              ]}>
            <Input readOnly />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Total">
          {window.idCurrency.formatSimple(subtotal)}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <ItemsInput
        branchId={branchId}
        add={addItem}
        items={items}
        deleteItem={deleteItem}
      />
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BuyForm;
