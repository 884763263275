import React, { useState } from "react";
import { Modal, Form, Checkbox, DatePicker } from "antd";
const { RangePicker } = DatePicker;

const DateSelect = props => {
  const { submit, anytime: anytimeProps, onCancel, ...rest } = props;
  const [form] = Form.useForm();
  const handleSubmit = values => {
      submit(values);
  };
  const handleFormSubmit = () => {
    form.submit();
  }
  const [anytime, setAnytime] = useState(anytimeProps);
  return (
    <Modal {...rest} onOk={handleFormSubmit} onCancel={onCancel} form={form}>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="anytime" valuePropName="checked" initialValue={anytime}>
            <Checkbox onChange={e => setAnytime(!anytime)}>
              Anytime
            </Checkbox>
        </Form.Item>
        <Form.Item name="range" label="Choose date range"  rules={[{ required: !anytime }]}>
            <RangePicker disabled={anytime} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DateSelect;
